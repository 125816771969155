import React from 'react';

interface Props{
  theme: string;
}

const TicketDivider:React.FC<Props> = (props) => {
  return (
    <div className='row-center gap12'>
      {/* <div className={`ticket-divider-circle-left bgBG1 ${props.theme}`} /> */}
      <div className={`ticket-divider-line borderDashBottom ${props.theme}`} />
      {/* <div className={`ticket-divider-circle-right bgBG1 ${props.theme}`} /> */}
    </div>
  );
}

export default TicketDivider;