import React, { useState } from 'react';
import TicketDivider from '../common/ticket-divider';
import { useTranslation } from 'react-i18next';
import PaymentPeriod from './payment_period';
import SwitchTextBtn from '../common/switch_text_btn';

interface Props {
  theme: string;
  logo: string;
  name: string;
  // labelColor: string;
  // labelText: string;
  productClick: () => void;
  payment: number;
}

const MyProductItem:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  const [currency, setCurrency] = useState(true);
  const switchToggle = (e:React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setCurrency(!currency);
  };

  return (
    <div className={`product-item col-stretch bgBG2 bg2Shadow gap8 ${props.theme}`} onClick={props.productClick}>
      <div className={'product-item-title gap8 col-start'}>
        <div className='row-center gap8'>
          <img className='product-item-logo' src={props.logo} alt='Axa logo' />
          {/* <ColorLabel
            theme={props.theme}
            name={props.labelText}
            color={props.labelColor}
          /> */}
        </div>
        <div className={`m17 c1 ${props.theme}`}>{props.name}</div>
      </div>
      <TicketDivider theme={props.theme} />
      <div className='p20'>
        <PaymentPeriod
          theme={props.theme}
          period={60}
          periodPaid={37}
        />
      </div>
      <TicketDivider theme={props.theme} />
      <div className='col-stretch p20'>
        <div className='my-product-item-info-title row-center jtf-between'>
          <div className={`b17 c1 ${props.theme}`}>{t('상품 요약')}</div>
          <SwitchTextBtn
            theme={props.theme}
            active={currency}
            onClick={(e:React.MouseEvent<HTMLDivElement>) => switchToggle(e)}
            leftText='$'
            rightText={t('원')}
            size='small'
          />
        </div>
        <div className={`my-product-item-info-item row-center jtf-between borderBottom05 ${props.theme}`}>
          <div className={`c5 m15 ${props.theme}`}>{t('월 납입금')}</div>
          <div className={`c1 r15 ${props.theme}`}>{props.payment.toLocaleString()} {t('원')}</div>
        </div>
        <div className={`my-product-item-info-item row-center jtf-between ${props.theme}`}>
          <div className={`c5 m15 ${props.theme}`}>{t('총 납입금')}</div>
          <div className={`c1 r15 ${props.theme}`}>{props.payment.toLocaleString()} {t('원')}</div>
        </div>
      </div>
    </div>
  );
};

export default MyProductItem;