import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {doc, getFirestore, updateDoc, } from 'firebase/firestore';

const updateFCMToken = async (token: string) => {
  const u = getAuth(getApp()).currentUser;

  if (u === null) {
    return;
  }

  const app = getApp();
  const db = getFirestore(app);

  const {claims} = await u.getIdTokenResult();        
  if (claims.authType === 'user') {
    await updateDoc(doc(db, 'kr_users', u.email!), {
      fcmToken: token,
    });
  } else if (claims.authType === 'seller') {
    await updateDoc(doc(db, 'kr_sellers', u.email!), {
      fcmToken: token,
    });
  }
};

export default updateFCMToken;
