import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType, useStack } from '@stackflow/react';
import React from 'react';
import { useFlow } from '../../../configs/stackflow';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import Appbar from '../../../components/appbar/appbar';
import { arrowBackDark, arrowBackLight } from '../../../configs/icon_route';
import BigBtn from '../../../components/btn/big_btn';

const InvitationPage: ActivityComponentType = () => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { pop } = useFlow();
  const { t } = useTranslation();
  const stack = useStack();
  console.log(stack);

  return (
    <AppScreen>
      <div className='frame-cover'>
        <div className={`frame ${type} p20 gap16 t64`}>
          <Appbar
            theme={type}
            type='text'
            title={t('초대하기')}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
          />
          <div className='invitation-frame'>
            <img className='invitation-img' src={'./img/home-invitation-big.png'} alt={t('초대')} />
            <div className='invitation-cards'>
              <div className={`c5 m17 ${type}`}>{t('보유한 초대장')}</div>
              <div className={`c1 b17 ${type}`}>{t('2장')}</div>
            </div>
            <BigBtn
              theme={type}
              type='primary'
              text='초대하기'
              onClick={() => console.log('sdf')}
              fill={true}
            />
            <div className={`invitation-info-wrap bgBG2 ${type}`}>
              <div className='invitation-info'>
                <div className={`m15paragraph c5 ${type}`}>-</div>
                <div className={`m15paragraph c5 ${type}`}>{t('초대장은 회원가입 시 2장 지급됩니다')}</div>
              </div>
              <div className='invitation-info'>
                <div className={`m15paragraph c5 ${type}`}>-</div>
                <div className={`m15paragraph c5 ${type}`}>{t('초대장을 보유한 유저만 블랙티켓 서비스에 회원가입 가능합니다')}</div>
              </div>
              <div className='invitation-info'>
                <div className={`m15paragraph c5 ${type}`}>-</div>
                <div className={`m15paragraph c5 ${type}`}>{t('초대장 발급 후 기한(1일)내에 사용하지 않을 시 해당 초대장은 회수됩니다')}</div>
              </div>
            </div>
            <div className={`invitation-help m15 c6 ${type}`}>{t('문의')} 1588-1588</div>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default InvitationPage;