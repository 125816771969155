const SNPTable = () => {
  return (
    <div className='table-wrapper radius8 borderC14 light'>
      <div className='table-header-1 m13'>최근 S&P500 연평균 수익률</div>
      <div className='table-row-1'>
        <div className='col-center label m13'>
          <div>최근 10년</div>
          <div className='r12'>(2015 ~ 2024.11)</div>
        </div>
        <div className='content m13'>14.42%</div>
      </div>
      <div className='table-divider'/>
      <div className='table-row-1'>
        <div className='col-center label m13'>
          <div>최근 20년</div>
          <div className='r12'>(2005 ~ 2024.11)</div>
        </div>
        <div className='content m13'>11.95%</div>
      </div>
      <div className='table-divider'/>
      <div className='table-row-1'>
        <div className='col-center label m13'>
          <div>최근 30년</div>
          <div className='r12'>(1995 ~ 2024.11)</div>
        </div>
        <div className='content m13'>12.64%</div>
      </div>
    </div>
  );
};

export default SNPTable;
