import { exchangeRate, exchangeRateHKD } from "configs/exchange_rate";

export const returnState = (state: string) => {
  if (state === 'ongoing') {
    return '진행 중'
  } else if (state === 'reject') {
    return '다시 제출'
  } else return 'error'
};

export const returnYear = (month: number) => {
  if (month < 12) {
    return 0;
  } else {
    return Math.floor(month / 12);
  }
}

export const returnMonth = (month: number) => {
  if (month < 12) {
    return month;
  } else {
    return month % 12;
  }
}

export const returnYearMonth = (month:number) => {
  if (month < 12) {
    return `${month}개월`;
  } else if (month % 12 === 0) {
    return `${month / 12}년`
  } else {
    return `${Math.floor(month / 12)}년 ${month % 12}개월`
  }
}

export const returnDate = (timestamp: Number): string => {
  const currentDate = new Date();
  const timestampDate = new Date(timestamp.valueOf());

  const diffTime = currentDate.getTime() - timestampDate.getTime();
  const diffMinutes = Math.floor(diffTime / (1000 * 60));
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    
  
  if (diffMinutes < 60) {
    return `${diffMinutes}분 전`;
  } else if (diffHours < 24) {
    return `${diffHours}시간 전`;
  } else if (diffDays < 7) {
    return `${diffDays}일 전`
  } else if (diffDays === 1) {
    return '어제';
  } else {
    const year = timestampDate.getFullYear();
    const month = timestampDate.getMonth() + 1;
    const day = timestampDate.getDate();
    return `${year}년 ${month}월 ${day}일`;
  }
}

export const returnAbsoluteDate = (timestamp: Number): string => {
  const timestampDate = new Date(timestamp.valueOf());
  const year = timestampDate.getFullYear();
  const month = timestampDate.getMonth() + 1;
  const day = timestampDate.getDate();

  return `${year.toString().slice(2, 4)}. ${month}. ${day}`;
}

export const returnAmountUnit = (amount: number, unit: string) => {
  if (unit === 'KRW') {
    if (amount >= 100000000) {
      const value = `${(amount / 100000000).toFixed(2)} 억`;
      return value;
    } else if (amount >= 10000) {
      const value = `${Math.floor((amount / 10000))} 만`;
      return value;
    } else if (amount === 0) {
      return 0;
    }
  } else {
    if (amount > 1000000000) {
      const value = `${(amount / 1000000000).toFixed(2)} b`;
      return value;
    } else if (amount > 1000000) {
      const value = `${(amount / 1000000).toFixed(2)} m`;
      return value;
    } else if (amount === 0) {
      return 0;
    }
  }
};

export const returnExchange = (amount:number, unit: string) => {
  if (unit === 'USD') {
    return amount * exchangeRate.USD;
  } else if (unit === 'HKD') {
    return amount * exchangeRate.HKD;
  }  else if (unit === 'KRW') {
    return amount * exchangeRate.KRW;
  } else return 0;
};

export const returnReverseExchange = (amount:number, unit: string) => {
  if (unit === 'USD') {
    return amount / exchangeRate.USD;
  } else if (unit === 'KRW') {
    return amount / exchangeRate.KRW;
  } else return 0;
};

export const returnExchangeHKD = (amount:number, unit: string) => {
  if (unit === 'HKD') {
    return amount / exchangeRate.HKD;
  } else if (unit === 'KRW') {
    return amount * exchangeRateHKD.KRW;
  } else return 0;
};