import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import BigBtn from 'components/btn/big_btn';
import MiddleBtn from 'components/btn/middle_btn';
import RowItem from 'components/common/row_item';
import UploadInput from 'components/input/upload_input';
import ModalYN from 'components/modal/modal_yn';
import { arrowBackDark, arrowBackLight, downloadDark, downloadLight } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import { List } from 'immutable';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import uploadTransfer from 'utils/firebase_firestore/upload_transfer';
import uploadFiles from 'utils/firebase_storage/upload_files';

type Params = {
  taskId: string;
  bankInfo: any;
  logo: string;
  productName: string;
  setToast: Dispatch<SetStateAction<string>>;
  file: any;
};

const RmtRequestPage: ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { pop } = useFlow();
  const { t } = useTranslation();

  const [fileA, setFileA] = useState<any>(params.file ?? List([]));
  const [fileValidA, setFileValidA] = useState('');

  const handleFileA = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileA(e.target.files?.[0]);
    setFileValidA('');
  };

  const openFileFinder = (id: string) => {
    if (document.querySelector(`input#${id}`)) {
      (document.querySelector(`input#${id}`)! as any).click();
    }
  };

  const [modal, setModal] = useState('');
  const modalSubmit = async () => {
    const url = await uploadFiles(params.taskId, [fileA]);
    const fa = {
      name: fileA.name,
      url,
    };

    await uploadTransfer(params.taskId, [fa]);

    params.setToast('rmtSubmit');
    pop();
  };
  const closeModal = () => {
    setModal('');
  };

  const submit = () => {
    if (fileA.name && !fileA.url) {
      setModal('submitCheck');
    }
  };

  return (
    <AppScreen>
      {
        modal === 'submitCheck' ?
        <ModalYN
          theme={type}
          text={t('송금증을 제출하시겠습니까?')}
          btnY={{name: t('확인'), onClick: () => modalSubmit()}}
          btnN={{name: t('취소'), onClick: () => closeModal()}}
        /> : null
      }
      <div className='frame-cover'>
        <div className={`frame ${type} p16 bgBG2 gap24 t64`}>
          <Appbar
            theme={type}
            type='text'
            title={'승금증 업로드'}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
          />
          <div className={`plan-request-product-wrap bgBG2 radius8 col-start gap12 ${type}`}>
            <img src={params.logo} alt='product logo' />
            <div className={`c1 m17 ${type}`}>{params.productName}</div>
          </div>
          <div className='col-stretch gap36'>
            <div className={`bgBG2 col-stretch ${type}`} style={{borderTop: 'solid 1px #ddd', borderBottom: 'solid 1px #ddd'}}>
                <RowItem
                  noPadding
                  theme={type}
                  label={t('은행명')}
                  text={params.bankInfo.bankName}
                  isLastItem={false}
                />
                <RowItem
                  noPadding
                  theme={type}
                  label={t('은행주소')}
                  text={params.bankInfo.bankAddress}
                  isLastItem={false}
                />
                <RowItem
                  noPadding
                  theme={type}
                  label={t('은행코드')}
                  text={params.bankInfo.bankCode}
                  isLastItem={false}
                />
                <RowItem
                  noPadding
                  theme={type}
                  label={t('계좌명')}
                  text={params.bankInfo.bankAccountName}
                  isLastItem={false}
                />
                <RowItem
                  noPadding
                  theme={type}
                  label={t('계좌번호')}
                  text={
                    <div style={{textAlign: 'right'}}>
                      {
                        params.bankInfo.bankAccount.map((ac: any, i: number) => {
                          return (
                            <div key={String(i)}>
                              {`${ac.addr} (${ac.curr})`}
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                  isLastItem={false}
                />
                <RowItem
                  noPadding
                  theme={type}
                  label={t('Swift Code')}
                  text={params.bankInfo.swiftCode}
                  isLastItem={true}
                />
            </div>
            <div className='col-stretch gap24'>
              <div className='col-start gap8'>
                <div className={`m15 c1 ${type}`}>
                  가이드
                </div>
                <div className={`upload-input-info r15 c7 ${type}`}>가이드 내용</div>
                <div className='w100'>
                  <MiddleBtn
                    theme={type}
                    icon={type === 'dark' ? downloadDark : downloadLight}
                    type='secondary'
                    text={'가이드 파일 다운로드'}
                    onClick={() => alert('download')}
                  />
                </div>
              </div>
              <UploadInput
                theme={type}
                label={'송금증(카드 결제 시 전표)'}
                info={['· 송금 후 송금증 혹은 카드 결제 시 전표를 업로드 해주세요']}
                file={fileA}
                setFile={setFileA}
                handleFile={(e:React.ChangeEvent<HTMLInputElement>) => handleFileA(e)}
                btn={{
                  text: '업로드',
                  onClick: () => openFileFinder('fileA')
                }}
                state={'normal'}
                id={'fileA'}
                validation={fileValidA !== '' ? false : true}
                validMessage={'파일이 업로드되지 않았습니다.'}
              />
            </div>
            <BigBtn
              theme={type}
              type='primary'
              text='제출'
              onClick={() => submit()}
            />
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default RmtRequestPage;