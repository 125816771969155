import { AppScreen } from '@stackflow/plugin-basic-ui';
import BigBtn from 'components/btn/big_btn';
import DropdownInput from 'components/input/dropdown_input';
import DropdownSheet from 'components/sheet/dropdown_sheet';
import { flagCn, flagJp, flagKr, flagTh, flagVn } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const SelectLocationPage = () => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { push } = useFlow();

  const [selectedItem, setSelectedItem] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const dropdownClose = () => {
    setDropdownOpen(false);
  };

  return (
    <AppScreen>
      {
        dropdownOpen ? 
        <DropdownSheet
          theme={type}
          type='flag'
          flagItems={[
            {name: t('대한민국'), flag: flagKr, disabled: false},
            {name: t('中国'), flag: flagCn, disabled: true},
            {name: t('日本'), flag: flagJp, disabled: true},
            {name: t('ประเทศไทย'), flag: flagTh, disabled: true},
            {name: t('việt nam'), flag: flagVn, disabled: true},
          ]}
          onClose={dropdownClose}
          setSelectedItem={setSelectedItem}
        /> : null
      }
      <div className='frame-cover'>
        <div className={`frame ${type} t56 p20 bgBG2`}>
          <div className='col-stretch gap20'>
            <div className='b20 c1 select-location-label'>{t('국적을 선택해주세요')}</div>
            <DropdownInput
              theme={type}
              type='flag'
              flagItem={[
                {name: t('대한민국'), flag: flagKr},
                {name: t('中国'), flag: flagCn},
                {name: t('日本'), flag: flagJp},
                {name: t('ประเทศไทย'), flag: flagTh},
                {name: t('việt nam'), flag: flagVn},
              ]}
              selectedItem={selectedItem}
              onClick={() => dropdownToggle()}
              placeholder={t('국적을 선택해주세요')}
            />
          </div>
          <div className='fixed-bottom-btn'>
            <BigBtn
              theme={type}
              type='primary'
              text={t('다음')}
              onClick={() => push("IntroducePage", {})}
              fill={true}
            />
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default SelectLocationPage;