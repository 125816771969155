import { AppScreen } from '@stackflow/plugin-basic-ui';
import { arrowIntroduceBack, arrowIntroduceNext, flagKr, introduce1, introduce2, introduce3 } from 'configs/icon_route';
import { useFlow } from 'configs/stackflow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const IntroducePage = () => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { pop, replace } = useFlow();

  const [step, setStep] = useState(0);

  const nextStep = () => {
    if (step !== 2) {
      setStep(step + 1);
    } else {
      replace("LoginPage", {}, {animate: false});
    }
  };

  const backStep = () => {
    if (step !== 0) {
      setStep(step - 1);
    } else {
      pop();
    }
  };

  return (
    <AppScreen>
      <div className={`introduce-appbar bgBG2 ${type}`}>
        <div className='introduce-back' onClick={() => pop()}>
          <img src={arrowIntroduceBack} alt='back' />
          <div className={`m15 c1 ${type}`}>{t('국가 선택')}</div>
        </div>
      </div>
      <div className='frame-cover'>
        <div className={`frame ${type} noPadding bgBG2`}>
          <div className='introduce-img-wrap'>
            {
              step === 0 ?
              <img className='introduce-img' src={introduce1} alt='' /> :
              step === 1 ?
              <img className='introduce-img' src={introduce2} alt='' /> :
              step === 2 ?
              <img className='introduce-img' src={introduce3} alt='' /> : null
            }
          </div>
          <div className='introduce-flag-wrap row-center gap6'>
            <img className='introduce-flag' src={flagKr} alt='korea flag' />
            <div className={`m15underline c1 ${type}`}>‘대한민국’ 선택됨</div>
          </div>
          {
            step === 0 ?
            <div className='introduce-text-wrap col-stretch gap8'>
              <div className='b20 c1'>{t('Insurein은 글로벌 보험 마켓입니다.')}</div>
              <div className='r17 c5'>{t('Insurein은 인슈어테크 전문기업이 만든 글로벌 보험마켓입니다. 전세계 다양한 보험상품 비교, 가입, 관리까지 원스톱으로 이용해보세요.')}</div>
            </div> :
            step === 1 ?
            <div className='introduce-text-wrap col-stretch gap8'>
              <div className='b20 c1'>{t('2024년 포브스 선정 글로벌 Top20 보험사들의 상품을 제공합니다.')}</div>
              <div className='r17 c5'>{t('금융선진국들에서 100년이 넘게 경쟁해온 보험사들은 고객을 유치하기 위해 더 많은 혜택을 제공합니다. 국내 보험사에서 결코 보지 못했던 혜택을 경험하세요.')}</div>
            </div> :
            step === 2 ?
            <div className='introduce-text-wrap col-stretch gap8'>
              <div className='b20 c1'>{t('대한민국 국민이 앱을 통해 외국 생명 보험을 가입하는 것은 합법입니다.')}</div>
              <div className='r17 c5'>{t('대한민국 보험업감독규정 제1-6조 1항에 따라 우편, 컴퓨터통신을 이용하여 외국보험회사와 보험계약을 체결 할 수 있습니다.')}</div>
            </div> : null
          }
          <div className='fixed-bottom-btn row-center jtf-between'>
            <div className={`introduce-back-btn radius30 bg15 ${type}`} onClick={() => backStep()}>
              <img src={arrowIntroduceBack} alt='back' />
              <div className={`m17 c1 ${type}`}>이전</div>
            </div>
            <div className='row-center gap8'>
              <div className={`introduce-dot ${step === 0 ? 'active' : ''}`}/>
              <div className={`introduce-dot ${step === 1 ? 'active' : ''}`}/>
              <div className={`introduce-dot ${step === 2 ? 'active' : ''}`}/>
            </div>
            <div className={`introduce-next-btn radius30 bgSDG`} onClick={() => nextStep()}>
              <div className={`m17 slg ${type}`}>다음</div>
              <img src={arrowIntroduceNext} alt='next' />
            </div>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default IntroducePage;