import React from 'react';
import { returnExchange, returnYearMonth } from 'utils/common/functions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useFlow } from 'configs/stackflow';

interface Props{
  theme: string;
  logo: string;
  name: string;
  company: string;
  payments: number;
  remainMonth: number;
  totalMonth: number;
  exchange: boolean;
}

const HomeMyInsurePaymentsItem:React.FC<Props> = (props) => {
  const { unit } = useSelector((state:RootState) => state.exchange);
  const { t } = useTranslation();

  return (
    <div
      className='home-my-insure-item'
    >
      <div className='col-start gap9'>
        <div className='row-center gap6'>
          <img className='home-my-insure-item-logo' src={props.logo} alt='보험사 로고' />
          <div className='m13 c1'>{props.company}</div>
        </div>
        <div className='col-start gap5'>
          <div className={`els2 m15 c3 ${props.theme}`}>{props.name}</div>
          <div className={`b20 c1 ${props.theme}`}>{returnExchange(props.payments, props.exchange ? 'USD' : unit)?.toLocaleString()} <span className={`m15 c7 ${props.theme}`}>{props.exchange ? 'USD' : unit} / year</span></div>
          <div className={`r15 c9 ${props.theme}`}>
            {t('청약 1년 후 신탁 명의변경 필요')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMyInsurePaymentsItem;