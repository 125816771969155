import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import { useFlow } from 'configs/stackflow';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ReactLoading from 'react-loading';

declare var window: any;
declare var mtw_req_fcm_token: any;

const LoadingPage: ActivityComponentType = () => {
  const {replace, pop} = useFlow();

  const {initialLoading, isLoggedIn} = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    window.wtm = {
      send_fcm_token: (token: any) => {
        // updateFcmToken(token);
      },
      send_fcm_data: (data: any) => {
        const d = JSON.parse(data.substring(1, data.length - 1));

      },
      send_device_locale: (locale: string) => {
      },
      backUrl: () => {
        pop();
      },
    };
  }, []);

  const updateActivityForSignedUser = async () => {
    replace('HomePage', {}, {animate: false});
  }

  useEffect(() => {
    if (!initialLoading) {
      if (isLoggedIn) {
        updateActivityForSignedUser();
      } else {
        replace('SelectLocationPage', {
        }, {animate: false});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoading, isLoggedIn]);

  return (
    <AppScreen>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white'}}>
        <ReactLoading type={'bubbles'} color={'#20363B'} height={'20%'} width={'20%'} />
      </div>
    </AppScreen>
  );
};

export default LoadingPage;
