import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useFlow } from '../../configs/stackflow';
import {
  appBanner00,
  appBanner01,
  appBanner02,
  arrowRightItemLight,
  homeBannerChat,
  homeBannerId,
  salesManageLight,
  settingLight,
} from '../../configs/icon_route';
import BottomNav from '../../components/common/bottom_nav';
import { useTranslation } from 'react-i18next';
import ExchangeBanner from '../../components/exchange/exchange_banner';
import Toast from '../../components/toast/toast';
import { getAuth, signOut } from 'firebase/auth';
import { getApp } from 'firebase/app';
import updateFCMToken from 'utils/firebase_firestore/update_fcm_token';
import HomeProductItem from 'components/home/product_item';
import products from 'configs/products';
import HomeMyInsure from 'components/home/my_insure';
import SectionTitle from 'components/common/section_title';
import HomeBlog from 'components/home/blog';
import HomeAppbar from 'components/appbar/home_appbar';
import HomeBlogItem from 'components/home/blog_item';
import { blogItems } from 'configs/blog';
import getExrateInfo from 'utils/firebase_firestore/get_exrate_info';
import { exchangeSlice } from 'store/reducers/exchangeReducer';
import { myDataSlice } from 'store/reducers/myDataReducer';
import getUser from 'utils/firebase_firestore/get_user';
import getUserTasksByUser from 'utils/firebase_firestore/get_user_tasks_by_user';
import createUser, { createUserDefaultData } from 'utils/firebase_firestore/create_user';
import ChannelService from 'configs/channel_service';
import HomeProduct from 'components/home/product';
import HotProductItems from 'components/home/hot_product_items';
import ReactLoading from 'react-loading';
import HomeReviewSection from 'components/review/home_review_section';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface MainProps {
  theme: string;
  setToastStart: Dispatch<SetStateAction<string>>;
  setBtmNavTab: Dispatch<SetStateAction<number>>;
}

interface BlogProps {
  theme: string;
}

interface ProductProps {
  theme: string;
  setToast: Dispatch<SetStateAction<string>>;
}

interface MyProps {
  theme: string;
  logOutClick: () => void;
  setToast: Dispatch<SetStateAction<string>>;
}

const HomePage: ActivityComponentType = () => {
  const { type } = useSelector((state: RootState) => state.theme);
  const [btmNavTab, setBtmNavTab] = useState(0);
  const { t } = useTranslation();

  const [toastStart, setToastStart] = useState('');

  // logOut
  const [logOutSignal, setLogOutSignal] = useState(false);
  const logOutClick = async () => {
    setLogOutSignal(true);
    await updateFCMToken('');
    await signOut(getAuth(getApp()));
  };

  return (
    <AppScreen>
      <HomeAppbar />
      <Toast
        theme={type}
        text={t('상품 신청 정보가 저장되었습니다.')}
        start={toastStart}
        setStart={setToastStart}
        toastName="productAppSaved"
      />
      <Toast
        theme={type}
        text={t('ID 카드가 저장되었습니다.')}
        start={toastStart}
        setStart={setToastStart}
        toastName="idCardSaved"
      />
      <Toast
        theme={type}
        text={t('지금은 신청기간이 아닙니다.')}
        start={toastStart}
        setStart={setToastStart}
        toastName="yetSeminar"
      />
      <div className="frame-cover bottom-nav home">
        {btmNavTab === 0 ? (
          <HomeMainPage theme={type} setToastStart={setToastStart} setBtmNavTab={setBtmNavTab} />
        ) : btmNavTab === 1 ? (
          <HomeBlogPage theme={type} />
        ) : btmNavTab === 2 ? (
          <HomeProductPage theme={type} setToast={setToastStart} />
        ) : btmNavTab === 3 ? (
          <HomeMyPage theme={type} logOutClick={logOutClick} setToast={setToastStart} />
        ) : null}
      </div>
      <BottomNav theme={type} activeIdx={btmNavTab} setActiveIdx={setBtmNavTab} />
    </AppScreen>
  );
};

const HomeMainPage: React.FC<MainProps> = (props) => {
  const dispatch = useDispatch();
  const { push } = useFlow();

  const [subsSwitch, setSubsSwitch] = useState(true);
  const toggleSubsSwitch = () => {
    setSubsSwitch(!subsSwitch);
  };

  const [prdSwitch, setPrdSwitch] = useState(true);
  const togglePrdSwitch = () => {
    setPrdSwitch(!prdSwitch);
  };

  const [hotProductsSwitch, setHotProductsSwitch] = useState(true);
  const toggleHotProductsSwitch = () => {
    setHotProductsSwitch(!hotProductsSwitch);
  };

  useEffect(() => {
    initExchangeRate();
    initReq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initExchangeRate = async () => {
    const data = await getExrateInfo();

    dispatch(exchangeSlice.actions.updateData(data));
  };

  const initReq = async () => {
    const currentUser = getAuth(getApp()).currentUser;
    const userId = currentUser?.uid ?? '';
    const phoneNumber = currentUser?.phoneNumber ?? '';

    const [myData, userTasks] = await Promise.all([getUser(userId), getUserTasksByUser(userId)]);

    if (!myData) {
      createUser(userId, phoneNumber);
    }

    const userData = myData ?? createUserDefaultData(userId, phoneNumber);

    dispatch(myDataSlice.actions.setData({ data: userData, tasks: userTasks }));
  };

  const [bannerPage, setBannerPage] = useState(0);

  const { loading, tasks } = useSelector((state: RootState) => state.myData);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: 'white',
        }}
      >
        <ReactLoading type={'bubbles'} color={'#20363B'} height={'20%'} width={'20%'} />
      </div>
    );
  }

  return (
    <div className={`frame ${props.theme} gap8 bottom-nav home t56`} style={{ paddingBottom: '8px' }}>
      <ExchangeBanner theme={props.theme} />
      <div style={{ position: 'relative' }}>
        <Carousel
          autoPlay
          infiniteLoop
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          onChange={(index: number, item: React.ReactNode) => {
            setBannerPage(index);
          }}
          onClickItem={async (i: number) => {
            if (i === 0) {
              const u = getAuth(getApp()).currentUser;
              push("BlogPostPage", {url: `https://seminar.insurein.life/?f=app&=${u?.phoneNumber}`});
            } else if (i === 1) {
              push('BlogPostPage', {
                url: 'https://blog.insurein.life/kr/app/%EC%9D%B8%EC%8A%90%EB%A6%B0%EC%9C%BC%EB%A1%9C-%EC%99%B8%EA%B5%AD%EB%B3%B4%ED%97%98-%EA%B0%80%EC%9E%85%ED%95%98%EA%B8%B0-1%ED%8E%B8',
              });
            } else if (i === 2) {
              push('IdCardPage', { idSaveComp: () => {} });
            }
          }}
        >
          <img src={appBanner00} alt="banner00" />
          <img src={appBanner01} alt="banner01" />
          <img src={appBanner02} alt="banner02" />
        </Carousel>
        <div
          className="m13 radius12"
          style={{
            position: 'absolute',
            bottom: '12px',
            right: '12px',
            padding: '2px 6px',
            color: 'white',
            backgroundColor: 'black',
          }}
        >{`${bannerPage + 1} / 3`}</div>
      </div>
      {loading || (tasks ?? []).filter((v: any) => v.step === 'COMPLETE').length === 0 ? null : (
        <HomeMyInsure
          theme={props.theme}
          switch={true}
          switchActive={subsSwitch}
          switchToggle={toggleSubsSwitch}
          items={tasks.filter((v: any) => v.step === 'COMPLETE')}
        />
      )}
      {loading ? null : (tasks ?? []).filter((v: any) => v.step !== 'COMPLETE').length === 0 ? null : (
        <HomeProduct
          theme={props.theme}
          switch={true}
          switchActive={prdSwitch}
          switchToggle={togglePrdSwitch}
          items={tasks.filter((v: any) => v.step !== 'COMPLETE')}
          goProductList={() => props.setBtmNavTab(2)}
        />
      )}
      <HotProductItems
        theme={props.theme}
        switch={true}
        switchActive={prdSwitch}
        switchToggle={togglePrdSwitch}
        items={[{ productCode: 'ZURICH_01' }]}
        goProductList={() => props.setBtmNavTab(2)}
      />
      <HomeReviewSection theme={props.theme} />
      <HomeBlog
        label="한번 읽어보세요"
        theme={props.theme}
        items={blogItems.slice(0, 3)}
        onClickMore={() => props.setBtmNavTab(1)}
      />
    </div>
  );
};

const HomeBlogPage: React.FC<BlogProps> = (props) => {
  const { push } = useFlow();

  return (
    <div className={`frame ${props.theme} gap8 t56 hasAppbar bottom-nav`} style={{ paddingBottom: '8px' }}>
      <div className={`${props.theme} bgBG2`}>
        <img
          style={{ width: '100%' }}
          src={appBanner01}
          alt="banner01"
          onClick={() =>
            push('BlogPostPage', {
              url: 'https://blog.insurein.life/kr/app/%EC%9D%B8%EC%8A%90%EB%A6%B0%EC%9C%BC%EB%A1%9C-%EC%99%B8%EA%B5%AD%EB%B3%B4%ED%97%98-%EA%B0%80%EC%9E%85%ED%95%98%EA%B8%B0-1%ED%8E%B8',
            })
          }
        />
        {blogItems.map((item: any, idx: number) => {
          return (
            <HomeBlogItem
              thumb={item.thumb}
              type={item.type}
              key={idx}
              theme={props.theme}
              id={item.id}
              title={item.title}
              date={item.date}
              isHome={false}
              url={item.url}
              isLastItem={idx === blogItems.length - 1}
            />
          );
        })}
      </div>
    </div>
  );
};

const HomeProductPage: React.FC<ProductProps> = (props) => {
  const [currency, setCurrency] = useState(true);

  const switchClick = () => {
    setCurrency(!currency);
  };

  // const {tasks} = useSelector((state: RootState) => state.myData);

  // if (loading) {
  //   return <div />;
  // }

  return (
    <div className={`frame ${props.theme} gap8 hasAppbar t56 bottom-nav bg2Shadow`} style={{ paddingBottom: '8px' }}>
      <div className={`col-stretch ${props.theme} bgBG2`}>
        <SectionTitle
          theme={props.theme}
          title={'상품'}
          switch={true}
          switchActive={currency}
          switchToggle={switchClick}
        />
        {products.map((product: any, idx: number) => {
          // const product = products.filter((p: any) => p.code === task.productCode)[0];
          // const state = task.isReject ? 'reject' : getTaskLabel(task.step, task.subStep, true);
          return (
            <HomeProductItem
              irrListLabel={product.irrListLabel}
              labels={product.labels}
              hideStatus
              productCode={product.code}
              key={idx.toString()}
              theme={props.theme}
              logo={product.logo}
              name={product.name}
              paymentPeriod={product.durations.map((d: any) => `${d}년`).join(' / ')}
              chips={product.allowMonthlyPaid ? ['월납가능'] : []}
              minPayment={product.minForYear}
              exchange={currency}
              isLast={products.length - 1 === idx}
              state={''}
              company={product.company}
            />
          );
        })}
      </div>
    </div>
  );
};

const HomeMyPage: React.FC<MyProps> = (props) => {
  const { t } = useTranslation();
  const { push } = useFlow();

  const { tasks, loading } = useSelector((state: RootState) => state.myData);
  // if (loading) {
  //   return <div />;
  // }

  const [subsSwitch, setSubsSwitch] = useState(true);
  const toggleSubsSwitch = () => {
    setSubsSwitch(!subsSwitch);
  };

  const idSaveComp = () => {
    props.setToast('idCardSaved');
  };

  return (
    <div className={`frame ${props.theme} gap16 t58 bottom-nav`}>
      <div className={`col-start gap36`}>
        <div className="col-stretch gap10 w100">
          <img
            src={homeBannerId}
            alt="아이디 카드 작성"
            onClick={() => push('IdCardPage', { idSaveComp: idSaveComp })}
          />
          <img src={homeBannerChat} alt="채팅 문의" onClick={() => ChannelService.showMessenger()} />
          <HomeMyInsure
            theme={props.theme}
            switch={true}
            switchActive={subsSwitch}
            switchToggle={toggleSubsSwitch}
            items={tasks.filter((v: any) => v.step === 'COMPLETE')}
          />
          <div className={`col-stretch bg2Shadow ${props.theme}`}>
            <div
              className={`home-mypage-row row-center jtf-between bgBG2 borderBottomC14W05 ${props.theme}`}
              onClick={() => push('IdCardPage', { idSaveComp: idSaveComp })}
            >
              <div className="row-center gap6">
                <img className="home-mypage-row-left-icon" src={salesManageLight} alt="ID 카드 정보 수정" />
                <div className={`m17 c5 ${props.theme}`}>ID 카드 정보 수정</div>
              </div>
              <img className="home-mypage-row-right-icon" src={arrowRightItemLight} alt="이동" />
            </div>
            <div
              className={`home-mypage-row row-center jtf-between bgBG2 ${props.theme}`}
              onClick={() => push('SettingPage', {})}
            >
              <div className="row-center gap6">
                <img className="home-mypage-row-left-icon" src={settingLight} alt="환경설정" />
                <div className={`m17 c5 ${props.theme}`}>환경설정</div>
              </div>
              <img className="home-mypage-row-right-icon" src={arrowRightItemLight} alt="이동" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
