import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import BigBtn from 'components/btn/big_btn';
import FullReviewCard from 'components/review/full_review_card';
import { arrowBackDark, arrowBackLight, reviewBannerReward } from 'configs/icon_route';
import reviewData from 'configs/review_data';
import { useFlow } from 'configs/stackflow';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Params = {
  productId: string;
}

const ReviewListsPage:ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { t } = useTranslation();
  const { pop } = useFlow();

  return (
    <AppScreen>
      <div className='frame-cover'>
        <div className={`frame ${type} t48 bgBG1`}>
          <Appbar
            theme={type}
            type='text'
            title={t('고객 후기')}
            borderBottom={false}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: () => pop(),
            }}
          />
          <div className='col-stretch' style={{paddingBottom: '64px'}}>
            <img alt='reviewBannerReward' src={reviewBannerReward} />
            {
              reviewData.filter((r) => {
                if (params.productId === 'all') {
                  return true;
                }

                return r.productId === params.productId;
              }).map((review: any, i: number) => {
                return (
                  <FullReviewCard {...review} />
                );
              })
            }
          </div>
        </div>
        <div style={{position: 'fixed', bottom: '0px', width: '100%', backgroundColor: 'white'}}>
          <div style={{padding: '10px 20px'}}>
            <BigBtn
              theme={type}
              type='primary'
              text={t('리뷰 작성')}
              onClick={() => {}}
              fill={true}
              disabled
            />
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default ReviewListsPage;