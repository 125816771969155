import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import Appbar from 'components/appbar/appbar';
import BigBtn from 'components/btn/big_btn';
import ProgressBar from 'components/common/progress_bar';
import MonthPicker from 'components/input/month_picker';
import RadioInput from 'components/input/radio_input';
import TextInput from 'components/input/text_input';
import UploadInput from 'components/input/upload_input';
import ModalCol from 'components/modal/modal_col';
import ModalOk from 'components/modal/modal_ok';
import ModalYN from 'components/modal/modal_yn';
import db from 'configs/db';
import { arrowBackDark, arrowBackLight } from 'configs/icon_route';
import products from 'configs/products';
import { useFlow } from 'configs/stackflow';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { List } from 'immutable';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { myDataSlice } from 'store/reducers/myDataReducer';
import { RootState } from 'store/store';
import getUser from 'utils/firebase_firestore/get_user';
import getUserTasksByUser from 'utils/firebase_firestore/get_user_tasks_by_user';
import saveContractReqInfo from 'utils/firebase_firestore/save_contract_req_info';
import uploadFiles from 'utils/firebase_storage/upload_files';

type Params = {
  logo: string;
  productName: string;
  setToast: Dispatch<SetStateAction<string>>;
  taskId: string;
  refresh: Function;
  productCode: string;
}

interface PropsA {
  code: string;
  theme: string;
  nextClick: () => void;
  backClick: () => void;

  payment: string;
  paymentValid: string;
  paymentChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  paymentDuration: number;
  paymentDurationChange: any;
  useMonthlyPayment: boolean;
  useMonthlyPaymentChange: any;
  payMethod: number | boolean;
  payMethodValid: string;
  payMethodChange: (idx:number) => void;
  cardType: number | boolean;
  cardTypeValid: string;
  cardTypeChange: (idx:number) => void;
  cardNumb: string;
  cardNumbValid: string;
  cardNumbChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  cardPeriod: Date | null;
  setCardPeriod: Dispatch<SetStateAction<Date | null>>;
  cardPeriodValid: string;
  setCardPeriodValid: Dispatch<SetStateAction<string>>;
  cardPeriodChange: (date: Date) => void;
  invoice: number | boolean;
  invoiceValid: string;
  invoiceChange: (idx:number) => void;
  invoiceEtc: string;
  invoiceEtcValid: string;
  invoiceEtcChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  policyAddress: number | boolean;
  policyAddressValid: string;
  policyAddressChange: (idx:number) => void;
  minForYear: number;
}

interface PropsB {
  theme: string;
  nextClick: () => void;
  backClick: () => void;

  name: string;
  nameValid: string;
  nameChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  gender: number | boolean;
  genderValid: string;
  genderChange: (idx:number) => void;
  birth: string;
  birthValid: string;
  birthChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  smoking: number | boolean;
  smokingValid: string;
  smokingChange: (idx:number) => void;
  passport: string;
  passportValid: string;
  passportChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  address: string;
  addressValid: string;
  addressChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  postNumb: string;
  postNumbValid: string;
  postNumbChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  email: string;
  emailValid: string;
  emailChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  fileA: any;
  fileB: any;
  setFileA: any;
  setFileB: any;
  fileAValid: string;
  fileBValid: string;
  setFileAValid: Dispatch<SetStateAction<string>>;
  setFileBValid: Dispatch<SetStateAction<string>>;
  handleFileA: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileB: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface PropsC {
  theme: string;
  nextClick: () => void;
  backClick: () => void;

  employerName: string;
  employerNameValid: string;
  employerNameChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  bsnType: string;
  bsnTypeValid: string;
  bsnTypeChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  bsnAddress: string;
  bsnAddressValid: string;
  bsnAddressChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  position: string;
  positionValid: string;
  positionChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  periodService: string;
  periodServiceValid: string;
  periodServiceChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
}

interface PropsD {
  theme: string;
  nextClick: () => void;
  backClick: () => void;

  income: string;
  incomeValid: string;
  incomeChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  liquidAsset: string;
  liquidAssetValid: string;
  liquidAssetChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
}

interface PropsE {
  theme: string;
  nextClick: () => void;
  backClick: () => void;

  name: string;
  nameValid: string;
  nameChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  regNumb: string;
  regNumbValid: string;
  regNumbChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  relation: string;
  relationValid: string;
  relationChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
}

const SubsRequestPage: ActivityComponentType<Params> = ({params}) => {
  const { type } = useSelector((state:RootState) => state.theme);
  const { pop } = useFlow();
  const { t } = useTranslation();
  const fRef = useRef<HTMLDivElement | null>(null);

  const product = products.filter((p: any) => p.code === params.productCode)[0];
// modal
  const [modal, setModal] = useState('');
  const openSaveModal = () => {
    setModal('save');
  };
  const closeModal = () => {
    setModal('');
  };
  const modalSaveLeave = async () => {
    await saveData(false);
    if (params.setToast) {
      params.setToast('reqSaved');
    }
    closeModal();
    pop();
  };
  const modalLeave = () => {
    if (params.setToast) {
      params.setToast('');
    }
    closeModal();
    pop();
  };
  const modalSubmit = async () => {
    await saveData(true);
    if (params.setToast) {
      params.setToast('reqSubmit');
    }
    closeModal();
    pop();
  };

  const [step, setStep] = useState(1);
  const backClick = () => {
    setStep(step - 1);
    fRef.current?.scrollTo(0, 0);
  };

// next
  const nextClickA = () => {
    if (
      payment !== '' &&
      parseInt(payment) >= product.minForYear &&
      (typeof payMethod === 'number') &&
      (((payMethod === 2 || payMethod === 3) && (typeof cardType === 'number')) || (payMethod === 0 || payMethod === 1)) &&
      (((payMethod === 2 || payMethod === 3) && cardNumb !== '') || (payMethod === 0 || payMethod === 1)) &&
      (((payMethod === 2 || payMethod === 3) && cardPeriod !== null) || (payMethod === 0 || payMethod === 1)) &&
      (typeof invoice === 'number') &&
      ((invoice === 4 && invoiceEtc !== '') || invoice !== 4) &&
      (typeof policyAddress === 'number')
    ) {
      setStep(step + 1);
      fRef.current?.scrollTo(0, 0);
    } else {
      if (payment === '') {
        setPaymentValid('notEntered');
      }
      if (parseInt(payment) < product.minForYear) {
        setPaymentValid('minError');
      }
      if (typeof payMethod !== 'number') {
        setPayMethodValid('notSelected');
      }
      if (payMethod === 0 || payMethod === 1) {
        setCardTypeValid('');
      } else if (typeof cardType !== 'number') {
        setCardTypeValid('notSelected');
      }
      if (payMethod === 0 || payMethod === 1) {
        setCardNumbValid('');
      } else if (cardNumb === '') {
        setCardNumbValid('notSelected');
      }
      if (payMethod === 0 || payMethod === 1) {
        setCardPeriodValid('');
      } else if (cardPeriod === null) {
        setCardPeriodValid('notSelected');
      }
      if (typeof invoice !== 'number') {
        setInvoiceValid('notSelected');
      } else if ((invoice === 4 && invoiceEtc === '')) {
        setInvoiceEtcValid('etcEmpty');
      }
      if (typeof policyAddress !== 'number') {
        setPolicyAddressValid('notSelected');
      }
      setModal('validCheck');
    }
  };

  const nextClickB = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const namePattern = /^[A-Za-z\s]+$/;
    const birthPattern = /^\d{8}$/;

    if (
      namePattern.test(ctName) &&
      (typeof ctGender === 'number') &&
      birthPattern.test(ctBirth) &&
      (typeof ctSmoking === 'number') &&
      ctPassport !== '' &&
      ctAddress !== '' &&
      ctPostNumb !== '' &&
      emailPattern.test(ctEmail) &&
      fileA !== List([]) &&
      fileB !== List([])
    ) {
      setStep(step + 1);
      fRef.current?.scrollTo(0, 0);
    } else {
      if (!namePattern.test(ctName)) {
        setCtNameValid('notEntered');
      }
      if (typeof ctGender !== 'number') {
        setCtGenderValid('notSelected');
      }
      if (!birthPattern.test(ctBirth)) {
        setCtBirthValid('notEntered');
      }
      if (typeof ctSmoking !== 'number') {
        setCtSmokingValid('notSelected');
      }
      if (ctPassport === '') {
        setCtPassportValid('notEntered');
      }
      if (ctAddress === '') {
        setCtAddressValid('notEntered');
      }
      if (ctPostNumb === '') {
        setCtPostNumbValid('notEntered');
      }
      if (!emailPattern.test(ctEmail)) {
        setCtEmailValid('notEntered');
      }
      if (fileA === List([])) {
        setFileValidA('notUpload');
      }
      if (fileB === List([])) {
        setFileValidB('notUpload');
      }
      setModal('validCheck');
    }
  };

  const nextClickC = () => {
    if (
      employerName !== '' &&
      bsnType !== '' &&
      bsnAddress !== '' &&
      position !== '' &&
      periodService !== ''
    ) {
      setStep(step + 1);
      fRef.current?.scrollTo(0, 0);
    } else {
      if (employerName === '') {
        setEmployerNameValid('notEntered');
      }
      if (bsnType === '') {
        setBsnTypeValid('notEntered');
      }
      if (bsnAddress === '') {
        setBsnAddressValid('notEntered');
      }
      if (position === '') {
        setPositionValid('notEntered');
      }
      if (periodService === '') {
        setPeriodServiceValid('notEntered');
      }
      setModal('validCheck');
    }
  };

  const nextClickD = () => {
    if (income !== '' && liquidAsset !== '') {
      setStep(step + 1);
      fRef.current?.scrollTo(0, 0);
    } else {
      if (income === '') {
        setIncomeValid('notEntered');
      }
      if (liquidAsset === '') {
        setLiquidAssetValid('notEntered');
      }
      setModal('validCheck');
    }
  };

  const nextClickE = () => {
    if (bnfName !== '' && bnfRegNumb !== '' && bnfRelation !== '') {
      setModal('submitCheck');
    } else {
      if (bnfName === '') {
        setBnfNameValid('notEntered');
      }
      if (bnfRegNumb === '') {
        setBnfRegNumbValid('notEntered');
      }
      if (bnfRelation === '') {
        setBnfRelationValid('notEntered');
      }
      setModal('validCheck');
    }
  };

// step 1
  const [payment, setPayment] = useState('');
  const [paymentValid, setPaymentValid] = useState('');
  const [useMonthlyPayment, setUseMonthlyPayment] = useState(false);
  const [paymentDuration, setPaymentDuration] = useState(0);
  const [payMethod, setPayMethod] = useState<number | boolean>(false);
  const [payMethodValid, setPayMethodValid] = useState('');
  const [cardType, setCardType] = useState<number | boolean>(false);
  const [cardTypeValid, setCardTypeValid] = useState('');
  const [cardNumb, setCardNumb] = useState('');
  const [cardNumbValid, setCardNumbValid] = useState('');
  const [cardPeriod, setCardPeriod] = useState<Date | null>(null);
  const [cardPeriodValid, setCardPeriodValid] = useState('');
  const [invoice, setInvoice] = useState<number | boolean>(false);
  const [invoiceValid, setInvoiceValid] = useState('');
  const [invoiceEtc, setInvoiceEtc] = useState('');
  const [invoiceEtcValid, setInvoiceEtcValid] = useState('');
  const [policyAddress, setPolicyAddress] = useState<number | boolean>(false);
  const [policyAddressValid, setPolicyAddressValid] = useState('');

  const paymentChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPayment(e.target.value);
    setPaymentValid('');
  };
  const payMethodChange = (idx: number) => {
    setPayMethod(idx);
    setPayMethodValid('');
  };
  const cardTypeChange = (idx: number) => {
    setCardType(idx);
    setCardTypeValid('');
  };
  const cardNumbChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCardNumb(e.target.value);
    setCardNumbValid('');
  };
  const cardPeriodChange = (date: Date) => {
    setCardPeriod(date);
    setCardPeriodValid('');
  };
  const invoiceChange = (idx: number) => {
    setInvoice(idx);
    setInvoiceEtc('');
    setInvoiceValid('');
  };
  const invoiceEtcChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceEtc(e.target.value);
    setInvoiceEtcValid('');
  };
  const policyAddressChange = (idx: number) => {
    setPolicyAddress(idx);
    setPolicyAddressValid('');
  };

// step 2
  const [ctName, setCtName] = useState('');
  const [ctNameValid, setCtNameValid] = useState('');
  const [ctGender, setCtGender] = useState<number | boolean>(false);
  const [ctGenderValid, setCtGenderValid] = useState('');
  const [ctBirth, setCtBirth] = useState('');
  const [ctBirthValid, setCtBirthValid] = useState('');
  const [ctSmoking, setCtSmoking] = useState<number | boolean>(false);
  const [ctSmokingValid, setCtSmokingValid] = useState('');
  const [ctPassport, setCtPassport] = useState('');
  const [ctPassportValid, setCtPassportValid] = useState('');
  const [ctAddress, setCtAddress] = useState('');
  const [ctAddressValid, setCtAddressValid] = useState('');
  const [ctPostNumb, setCtPostNumb] = useState('');
  const [ctPostNumbValid, setCtPostNumbValid] = useState('');
  const [ctEmail, setCtEmail] = useState('');
  const [ctEmailValid, setCtEmailValid] = useState('');

  const ctNameChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCtNameValid('');
    setCtName(e.target.value);
  };
  const ctGenderChange = (idx: number) => {
    setCtGenderValid('');
    setCtGender(idx);
  };
  const ctBirthChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCtBirthValid('');
    setCtBirth(e.target.value);
  };
  const ctSmokingChange = (idx: number) => {
    setCtSmokingValid('');
    setCtSmoking(idx);
  };
  const ctPassportChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCtPassportValid('');
    setCtPassport(e.target.value);
  };
  const ctAddressChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCtAddressValid('');
    setCtAddress(e.target.value);
  };
  const ctPostNumbChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCtPostNumbValid('');
    setCtPostNumb(e.target.value);
  };
  const ctEmailChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCtEmailValid('');
    setCtEmail(e.target.value);
  };

  const [fileA, setFileA] = useState<any>(List([]));
  const [fileB, setFileB] = useState<any>(List([]));
  const [fileValidA, setFileValidA] = useState('');
  const [fileValidB, setFileValidB] = useState('');

  const handleFileA = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileA(e.target.files?.[0]);
    setFileValidA('');
  };
  const handleFileB = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFileB(e.target.files?.[0]);
    setFileValidB('');
  };

//step 3
  const [employerName, setEmployerName] = useState('');
  const [employerNameValid, setEmployerNameValid] = useState('');
  const [bsnType, setBsnType] = useState('');
  const [bsnTypeValid, setBsnTypeValid] = useState('');
  const [bsnAddress, setBsnAddress] = useState('');
  const [bsnAddressValid, setBsnAddressValid] = useState('');
  const [position, setPosition] = useState('');
  const [positionValid, setPositionValid] = useState('');
  const [periodService, setPeriodService] = useState('');
  const [periodServiceValid, setPeriodServiceValid] = useState('');

  const employerNameChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setEmployerNameValid('');
    setEmployerName(e.target.value);
  };
  const bsnTypeChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setBsnTypeValid('');
    setBsnType(e.target.value);
  };
  const bsnAddressChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setBsnAddressValid('');
    setBsnAddress(e.target.value);
  };
  const positionChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPositionValid('');
    setPosition(e.target.value);
  };
  const periodServiceChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPeriodServiceValid('');
    setPeriodService(e.target.value);
  };

//step 4
  const [income, setIncome] = useState('');
  const [incomeValid, setIncomeValid] = useState('');
  const [liquidAsset, setLiquidAsset] = useState('');
  const [liquidAssetValid, setLiquidAssetValid] = useState('');

  const incomeChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setIncomeValid('');
    setIncome(e.target.value);
  };
  const liquidAssetChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setLiquidAssetValid('');
    setLiquidAsset(e.target.value);
  };

//step 5
  const [bnfName, setBnfName] = useState('');
  const [bnfNameValid, setBnfNameValid] = useState('');
  const [bnfRegNumb, setBnfRegNumb] = useState('');
  const [bnfRegNumbValid, setBnfRegNumbValid] = useState('');
  const [bnfRelation, setBnfRelation] = useState('');
  const [bnfRelationValid, setBnfRelationValid] = useState('');

  const bnfNameChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setBnfNameValid('');
    setBnfName(e.target.value);
  };
  const bnfRegNumbChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setBnfRegNumbValid('');
    if (e.target.value.length > 6 && !bnfRegNumb.includes('-')) {
      setBnfRegNumb(`${e.target.value.slice(0, 6)} - ${e.target.value.slice(6)}`);
    } else {
      setBnfRegNumb(e.target.value);
    }
  };
  const bnfRelationChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setBnfRelationValid('');
    setBnfRelation(e.target.value);
  };

  const [code, setCode] = useState('');

  useEffect(() => {
    req();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const req = async () => {
    const d = await getDoc(doc(db.getKrTaskCol(), params.taskId));
    const {contractReqInfo, productCode} = (d.data() as any);
    setCode(productCode);
    if (contractReqInfo) {
      setPayment(contractReqInfo.yearlyPaymentUSD);
      setUseMonthlyPayment(contractReqInfo.useMonthlyPayment ?? false);
      setPayMethod(contractReqInfo.paymentMethod);
      setCardType(contractReqInfo.cardInfoType);
      setCardNumb(contractReqInfo.cardInfoNo);
      setCardPeriod(contractReqInfo.cardInfoValidDur);
      setInvoice(contractReqInfo.invoiceInfo);
      setInvoiceEtc(contractReqInfo.invoiceEtc);
      setPolicyAddress(contractReqInfo.insuranceContractDeliveryAddrType);
      setCtName(contractReqInfo.enName);
      setCtGender(contractReqInfo.gender);
      setCtBirth(contractReqInfo.birth);
      setCtSmoking(contractReqInfo.isSmoke);
      setCtPassport(contractReqInfo.passportNo);
      setCtAddress(contractReqInfo.address);
      setCtPostNumb(contractReqInfo.zipcode);
      setCtEmail(contractReqInfo.email);
      setEmployerName(contractReqInfo.companyInfoCeoName);
      setBsnType(contractReqInfo.companyInfoBusType);
      setBsnAddress(contractReqInfo.companyInfoAddr);
      setPosition(contractReqInfo.companyInfoJobDuty);
      setPeriodService(contractReqInfo.companyInfoDuration);
      setIncome(contractReqInfo.assetInfoAnnualSalary);
      setLiquidAsset(contractReqInfo.assetInfoTotalAsset);
      setBnfName(contractReqInfo.beneficiaryInfoEnName);
      setBnfRegNumb(contractReqInfo.beneficiaryInfoPNo);
      setBnfRelation(contractReqInfo.beneficiaryInfoRelations);
      if (contractReqInfo.passportFile) {
        setFileA(contractReqInfo.passportFile);
      }
      if (contractReqInfo.rrFile) {
        setFileB(contractReqInfo.rrFile);
      }
      const product = products.filter((p: any) => p.code === productCode)[0];
      setPaymentDuration(contractReqInfo.paymentDuration ?? product.durations[0]);
    } else {
      const product = products.filter((p: any) => p.code === productCode)[0];
      setPaymentDuration(product.durations[0]);
    }
  };

  const dispatch = useDispatch();
  const saveData = async (confirm: boolean) => {
    let fa = null;
    let fb = null;

    if (fileA !== List([])) {
      if (fileA.url) {
        fa = fileA;
      } else {
        const url = await uploadFiles(params.taskId, [fileA]);
        fa = {
          name: fileA.name,
          url,
        };
      }
    }
  
    if (fileB !== List([])) {
      if (fileB.url) {
        fb = fileB;
      } else {
        const url = await uploadFiles(params.taskId, [fileB]);
        fb = {
          name: fileB.name,
          url,
        };
      }
    }

    await saveContractReqInfo(params.taskId, {
      yearlyPaymentUSD: payment,
      useMonthlyPayment: useMonthlyPayment,
      paymentMethod: payMethod,
      cardInfoType: cardType,
      cardInfoNo: cardNumb,
      cardInfoValidDur: cardPeriod,
      invoiceInfo: invoice,
      invoiceEtc: invoiceEtc,
      insuranceContractDeliveryAddrType: policyAddress,
      enName: ctName,
      gender: ctGender,
      birth: ctBirth,
      isSmoke: ctSmoking,
      passportNo: ctPassport,
      address: ctAddress,
      zipcode: ctPostNumb,
      email: ctEmail,
      companyInfoCeoName: employerName,
      companyInfoBusType: bsnType,
      companyInfoAddr: bsnAddress,
      companyInfoJobDuty: position,
      companyInfoDuration: periodService,
      assetInfoAnnualSalary: income,
      assetInfoTotalAsset: liquidAsset,
      beneficiaryInfoEnName: bnfName,
      beneficiaryInfoPNo: bnfRegNumb,
      beneficiaryInfoRelations: bnfRelation,
      passportFile: fa,
      rrFile: fb,
      paymentDuration,
    }, confirm);

    if (confirm) {
      // refresh
      params.refresh();

      const email = getAuth(getApp()).currentUser?.email ?? '';

      const [myData, userTasks] = await Promise.all([
        getUser(email),
        getUserTasksByUser(email),
      ]);
  
      dispatch(myDataSlice.actions.setData({data: myData, tasks: userTasks}));
    }
  };

  return (
    <AppScreen>
      {
        modal === 'save' ?
        <ModalCol
          theme={type}
          text={t('저장되지 않은 정보가 있습니다. 저장 후 페이지를 떠나시겠습니까?')}
          btns={[
            {type: 'primary', text: t('저장 후 떠나기'), onClick: () => modalSaveLeave()},
            {type: 'otherWarning', text: t('저장하지 않고 떠나기'), onClick: () => modalLeave()},
            {type: 'other', text: t('취소'), onClick: () => closeModal()}
          ]}
        /> :
        modal === 'validCheck' ?
        <ModalOk
          theme={type}
          text={t('입력한 정보를 확인해주세요.')}
          btn={{name: t('확인'), onClick: () => closeModal()}}
        /> : 
        modal === 'submitCheck' ?
        <ModalYN
          theme={type}
          text={t('작성한 정보로 청약서를 요청하시겠습니까?')}
          btnY={{name: t('확인'), onClick: () => modalSubmit()}}
          btnN={{name: t('취소'), onClick: () => closeModal()}}
        /> : null
      }
      <div className='frame-cover'>
        <div className={`frame ${type} p16 gap24 t64 bgBG2`} ref={fRef}>
          <Appbar
            theme={type}
            type='text'
            title={t('청약서 요청')}
            left={{
              icon: type === 'dark' ? arrowBackDark : arrowBackLight,
              onClick: step === 1 ? () => openSaveModal() : () => backClick(),
            }}
          />
          <ProgressBar
            theme={type}
            stepLength={5}
            currentStep={step}
            label={
              step === 1 ? t('설계서 요청') :
              step === 2 ? t('보험계약자 정보 입력') :
              step === 3 ? t('회사 정보 입력') :
              step === 4 ? t('자산 정보 입력') :
              t('수익자 정보 입력')
            }
          />
          <div className={`plan-request-product-wrap bgBG2 radius8 row-center gap12 ${type}`}>
            <img src={params.logo} alt='product logo' />
            <div className={`c1 m17 ${type}`}>{params.productName}</div>
          </div>
          {
            step === 1 ?
            <SubsRequestA
              code={code}
              theme={type}
              nextClick={nextClickA}
              backClick={backClick}
              payment={payment}
              paymentValid={paymentValid}              
              paymentChange={paymentChange}
              paymentDuration={paymentDuration}
              paymentDurationChange={setPaymentDuration}
              useMonthlyPayment={useMonthlyPayment}
              useMonthlyPaymentChange={setUseMonthlyPayment}
              payMethod={payMethod}
              payMethodValid={payMethodValid}
              payMethodChange={payMethodChange}
              cardType={cardType}
              cardTypeValid={cardTypeValid}
              cardTypeChange={cardTypeChange}
              cardNumb={cardNumb}
              cardNumbValid={cardNumbValid}
              cardNumbChange={cardNumbChange}
              cardPeriod={cardPeriod}
              setCardPeriod={setCardPeriod}
              cardPeriodValid={cardPeriodValid}
              setCardPeriodValid={setCardPeriodValid}
              cardPeriodChange={cardPeriodChange}
              invoice={invoice}
              invoiceValid={invoiceValid}
              invoiceChange={invoiceChange}
              invoiceEtc={invoiceEtc}
              invoiceEtcValid={invoiceEtcValid}
              invoiceEtcChange={invoiceEtcChange}
              policyAddress={policyAddress}
              policyAddressValid={policyAddressValid}
              policyAddressChange={policyAddressChange}
              minForYear={product.minForYear}
            /> :
            step === 2 ?
            <SubsRequestB
              theme={type}
              nextClick={nextClickB}
              backClick={backClick}
              name={ctName}
              nameValid={ctNameValid}
              nameChange={ctNameChange}
              gender={ctGender}
              genderValid={ctGenderValid}
              genderChange={ctGenderChange}
              birth={ctBirth}
              birthValid={ctBirthValid}
              birthChange={ctBirthChange}
              smoking={ctSmoking}
              smokingValid={ctSmokingValid}
              smokingChange={ctSmokingChange}
              passport={ctPassport}
              passportValid={ctPassportValid}
              passportChange={ctPassportChange}
              address={ctAddress}
              addressValid={ctAddressValid}
              addressChange={ctAddressChange}
              postNumb={ctPostNumb}
              postNumbValid={ctPostNumbValid}
              postNumbChange={ctPostNumbChange}
              email={ctEmail}
              emailValid={ctEmailValid}
              emailChange={ctEmailChange}
              fileA={fileA}
              fileB={fileB}
              setFileA={setFileA}
              setFileB={setFileB}
              fileAValid={fileValidA}
              fileBValid={fileValidB}
              setFileAValid={setFileValidA}
              setFileBValid={setFileValidB}
              handleFileA={handleFileA}
              handleFileB={handleFileB}
            /> :
            step === 3 ?
            <SubsRequestC
              theme={type}
              nextClick={nextClickC}
              backClick={backClick}
              employerName={employerName}
              employerNameValid={employerNameValid}
              employerNameChange={employerNameChange}
              bsnType={bsnType}
              bsnTypeValid={bsnTypeValid}
              bsnTypeChange={bsnTypeChange}
              bsnAddress={bsnAddress}
              bsnAddressValid={bsnAddressValid}
              bsnAddressChange={bsnAddressChange}
              position={position}
              positionValid={positionValid}
              positionChange={positionChange}
              periodService={periodService}
              periodServiceValid={periodServiceValid}
              periodServiceChange={periodServiceChange}
            /> :
            step === 4 ?
            <SubsRequestD
              theme={type}
              nextClick={nextClickD}
              backClick={backClick}
              income={income}
              incomeValid={incomeValid}
              incomeChange={incomeChange}
              liquidAsset={liquidAsset}
              liquidAssetValid={liquidAssetValid}
              liquidAssetChange={liquidAssetChange}
            /> :
            <SubsRequestE
              theme={type}
              nextClick={nextClickE}
              backClick={backClick}
              name={bnfName}
              nameValid={bnfNameValid}
              nameChange={bnfNameChange}
              regNumb={bnfRegNumb}
              regNumbValid={bnfRegNumbValid}
              regNumbChange={bnfRegNumbChange}
              relation={bnfRelation}
              relationValid={bnfRelationValid}
              relationChange={bnfRelationChange}
            />
          }
        </div>
      </div>
    </AppScreen>
  );
};

const SubsRequestA:React.FC<PropsA> = (props) => {
  const {t} = useTranslation();

  const product = products.filter((p: any) => p.code === props.code)[0];

  console.log(product);
  if (!product) {
    return <div />;
  }

  return (
    <div className='col-stretch gap36'>
      <div className='col-stretch gap24'>
        <TextInput
          theme={props.theme}
          label={t('연납 보험료(USD)')}
          inputValue={props.payment}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.paymentChange(e)}
          placeholder={t('연납 보험료(USD) 입력...')}
          icon={false}
          validation={props.paymentValid !== '' ? false: true}
          validMessage={props.paymentValid === 'notEntered' ? t('연납 보험료를 확인해주세요.') : props.paymentValid === 'minError' ? t('최소금액을 확인해주세요.') : ''}
          inputType='tel'
        />
        {
          product && product.allowMonthlyPaid ? (
            <div className={`col-start gap8 w100 ${props.theme}`}>
              <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('납입방식')}</div>
              <div className={`col-stretch border w100 radius8 ${props.theme}`}>
                <RadioInput
                  theme={props.theme}
                  isActive={!props.useMonthlyPayment}
                  onClick={() => props.useMonthlyPaymentChange(false)}
                  text={t('연납')}
                  padding={true}
                />
                <RadioInput
                  theme={props.theme}
                  isActive={props.useMonthlyPayment}
                  onClick={() => props.useMonthlyPaymentChange(true)}
                  text={t('월납')}
                  padding={true}
                />
              </div>
            </div>
          ) : null
        }
        <div className={`col-start gap12 w100 ${props.theme}`}>
          <div className='col-start gap6'>
            <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('납입 연수')}</div>
          </div>
          <div className={`col-stretch border w100 radius8 ${props.theme}`}>
            {
              product.durations.map((d: any, i: number) => {
                return <RadioInput
                  key={String(i)}
                  theme={props.theme}
                  isActive={props.paymentDuration === d}
                  onClick={() => props.paymentDurationChange(d)}
                  text={`${d}년`}
                  padding={true}
                />
              })
            }
          </div>
        </div>
        <div className={`col-start gap12 w100 ${props.theme}`}>
          <div className='col-start gap6'>
            <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('납입방법')}</div>
            <div className={`c7 m15 ${props.theme}`}>{t('납입 방법을 선택해 주세요. 납입 방법이 카드납일 경우 아래의 카드정보를 반드시 기입해주세요.')}</div>
          </div>
          <div className={`col-stretch border w100 radius8 ${props.theme}`}>
            <RadioInput
              theme={props.theme}
              isActive={props.payMethod === 0}
              onClick={() => props.payMethodChange(0)}
              text={t('카카오 온라인 트랜스퍼')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.payMethod === 1}
              onClick={() => props.payMethodChange(1)}
              text={t('은행 이체')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.payMethod === 2}
              onClick={() => props.payMethodChange(2)}
              text={t('신용카드 결제')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.payMethod === 3}
              onClick={() => props.payMethodChange(3)}
              text={t('신용카드 결제 + 송금')}
              padding={true}
            />
          </div>
          {
            props.payMethodValid !== '' ?
            <div className={`radio-group-valid-wrap m13 colorRed ${props.theme}`}>{t('성별을 선택해주세요.')}</div> : null
          }
        </div>
        {
          props.payMethod === 2 || props.payMethod === 3 ?
          <>
            <div className={`col-start gap8 w100 ${props.theme}`}>
              <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('카드 종류')}</div>
              <div className={`col-stretch border w100 radius8 ${props.theme}`}>
                <RadioInput
                  theme={props.theme}
                  isActive={props.cardType === 0}
                  onClick={() => props.cardTypeChange(0)}
                  text={t('Visa')}
                  padding={true}
                />
                <RadioInput
                  theme={props.theme}
                  isActive={props.cardType === 1}
                  onClick={() => props.cardTypeChange(1)}
                  text={t('Master')}
                  padding={true}
                />
                <RadioInput
                  theme={props.theme}
                  isActive={props.cardType === 2}
                  onClick={() => props.cardTypeChange(2)}
                  text={t('American Express')}
                  padding={true}
                />
              </div>
              {
                props.cardTypeValid !== '' ?
                <div className={`radio-group-valid-wrap m13 colorRed ${props.theme}`}>{t('카드 종류를 선택해주세요.')}</div> : null
              }
            </div>
            <TextInput
              theme={props.theme}
              label={t('신용카드 번호')}
              inputValue={props.cardNumb}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.cardNumbChange(e)}
              placeholder={t('신용카드 번호 입력...')}
              icon={false}
              validation={props.cardNumbValid !== '' ? false: true}
              validMessage={t('신용카드 번호를 확인해주세요.')}
              inputType='tel'
            />
            <div
              className={`text-input`}
            >
              <div className='text-input-labels-wrap'>
                <div className={`m15 c1 ${props.theme}`}>
                  {t('신용카드 유효기간')}
                </div>
              </div>
              <MonthPicker
                theme={props.theme}
                value={props.cardPeriod}
                setValue={props.setCardPeriod}
                setValid={props.setCardPeriodValid}
              />
              {
                props.cardPeriodValid !== '' ?
                <div className={`radio-group-valid-wrap m13 colorRed ${props.theme}`}>{t('신용카드 유효기간을 입력해주세요.')}</div> : null
              }
            </div>
          </> : null
        }
        <div className={`col-start gap12 w100 ${props.theme}`}>
          <div className='col-start gap6'>
            <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('인보이스 필요여부')}</div>
            <div className={`c7 m15 ${props.theme}`}>{t('청약과 동시에 송금이 필요할 경우 요청해 주세요.')}</div>
          </div>
          <div className={`col-stretch border w100 radius8 ${props.theme}`}>
            <RadioInput
              theme={props.theme}
              isActive={props.invoice === 0}
              onClick={() => props.invoiceChange(0)}
              text={t('인보이스 불필요')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.invoice === 1}
              onClick={() => props.invoiceChange(1)}
              text={t('홍콩달러 인보이스')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.invoice === 2}
              onClick={() => props.invoiceChange(2)}
              text={t('미국달러 인보이스')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.invoice === 3}
              onClick={() => props.invoiceChange(3)}
              text={t('홍콩달러 + 미국달러 인보이스')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.invoice === 4}
              onClick={() => props.invoiceChange(4)}
              text={t('기타')}
              padding={true}
            />
            {
              props.invoice === 4 ?
              <div className='radio-text-input-wrap'>
                <TextInput
                  theme={props.theme}
                  inputValue={props.invoiceEtc}
                  onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.invoiceEtcChange(e)}
                  placeholder={t('기타 내용 입력...')}
                  icon={false}
                  validation={props.invoiceEtcValid === 'etcEmpty' ? false : true}
                  validMessage={t('기타 내용을 확인해주세요.')}
                  inputType='text'
                />
              </div> : null
            }
          </div>
          {
            props.invoiceValid === 'notSelected' ?
            <div className={`radio-group-valid-wrap m13 colorRed ${props.theme}`}>{t('인보이스 필요여부를 선택해주세요.')}</div> : null
          }
        </div>
        <div className={`col-start gap8 w100 ${props.theme}`}>
          <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('보험증서 발행주소')}</div>
          <div className={`col-stretch border w100 radius8 ${props.theme}`}>
            <RadioInput
              theme={props.theme}
              isActive={props.policyAddress === 0}
              onClick={() => props.policyAddressChange(0)}
              text={t('집')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.policyAddress === 1}
              onClick={() => props.policyAddressChange(1)}
              text={t('사무실')}
              padding={true}
            />
          </div>
          {
            props.policyAddressValid !== '' ?
            <div className={`radio-group-valid-wrap m13 colorRed ${props.theme}`}>{t('보험증서 발행주소를 선택해주세요.')}</div> : null
          }
        </div>
      </div>
      <BigBtn
        theme={props.theme}
        type='primary'
        text={t('다음')}
        onClick={() => props.nextClick()}
      />
    </div>
  );
};

const SubsRequestB:React.FC<PropsB> = (props) => {
  const {t} = useTranslation();

  const openFileFinder = (id: string) => {
    if (id === 'fileA') {
      props.setFileAValid('');
    } else if (id === 'fileB') {
      props.setFileBValid('');
    }
    if (document.querySelector(`input#${id}`)) {
      (document.querySelector(`input#${id}`)! as any).click();
    }
  };

  return (
    <div className='col-stretch gap36'>
      <div className='col-stretch gap24'>
        <TextInput
          theme={props.theme}
          label={t('영문 이름(여권과 동일)')}
          inputValue={props.name}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.nameChange(e)}
          placeholder={t('영문 이름 입력...')}
          icon={false}
          validation={props.nameValid !== '' ? false: true}
          validMessage={t('영문 이름을 확인해주세요.')}
          inputType='text'
        />
        <div className={`col-start gap8 w100 ${props.theme}`}>
          <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('성별')}</div>
          <div className={`col-stretch border w100 radius8 ${props.theme}`}>
            <RadioInput
              theme={props.theme}
              isActive={props.gender === 0}
              onClick={() => props.genderChange(0)}
              text={t('남성')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.gender === 1}
              onClick={() => props.genderChange(1)}
              text={t('여성')}
              padding={true}
            />
          </div>
          {
            props.genderValid !== '' ?
            <div className={`radio-group-valid-wrap m13 colorRed ${props.theme}`}>{t('성별을 선택해주세요.')}</div> : null
          }
        </div>
        <TextInput
          theme={props.theme}
          label={t('생년월일')}
          inputValue={props.birth}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.birthChange(e)}
          placeholder={t('생년월일 8자리 입력...')}
          icon={false}
          validation={props.birthValid !== '' ? false: true}
          validMessage={t('생년월일 8자리를 확인해주세요.')}
          inputType='tel'
          maxLength={8}
        />
        <div className={`col-start gap8 w100 ${props.theme}`}>
          <div className={`radio-group-label m15 c1 ${props.theme}`}>{t('흡연여부')}</div>
          <div className={`col-stretch border w100 radius8 ${props.theme}`}>
            <RadioInput
              theme={props.theme}
              isActive={props.smoking === 0}
              onClick={() => props.smokingChange(0)}
              text={t('흡연자')}
              padding={true}
            />
            <RadioInput
              theme={props.theme}
              isActive={props.smoking === 1}
              onClick={() => props.smokingChange(1)}
              text={t('비흡연자')}
              padding={true}
            />
          </div>
          {
            props.smokingValid !== '' ?
            <div className={`radio-group-valid-wrap m13 colorRed ${props.theme}`}>{t('흡연여부를 선택해주세요.')}</div> : null
          }
        </div>
        <TextInput
          theme={props.theme}
          label={t('여권번호')}
          inputValue={props.passport}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.passportChange(e)}
          placeholder={t('여권번호 입력...')}
          icon={false}
          validation={props.passportValid !== '' ? false: true}
          validMessage={t('여권번호를 확인해주세요.')}
          inputType='text'
        />
        <TextInput
          theme={props.theme}
          label={t('주소')}
          inputValue={props.address}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.addressChange(e)}
          placeholder={t('주소 입력...')}
          icon={false}
          validation={props.addressValid !== '' ? false: true}
          validMessage={t('주소를 확인해주세요.')}
          inputType='text'
        />
        <TextInput
          theme={props.theme}
          label={t('우편번호')}
          inputValue={props.postNumb}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.postNumbChange(e)}
          placeholder={t('우편번호 입력...')}
          icon={false}
          validation={props.postNumbValid !== '' ? false: true}
          validMessage={t('우편번호를 확인해주세요.')}
          inputType='tel'
        />
        <TextInput
          theme={props.theme}
          label={t('이메일')}
          inputValue={props.email}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.emailChange(e)}
          placeholder={t('이메일 입력...')}
          icon={false}
          validation={props.emailValid !== '' ? false: true}
          validMessage={t('이메일을 확인해주세요.')}
          inputType='email'
        />
        <UploadInput
          theme={props.theme}
          label={t('여권 사본')}
          info={[t('· 유효기간 6개월 이상 / 서명 必 / 컬러본')]}
          file={props.fileA}
          setFile={props.setFileA}
          handleFile={(e:React.ChangeEvent<HTMLInputElement>) => props.handleFileA(e)}
          btn={{
            text: t('업로드'),
            onClick: () => openFileFinder('fileA')
          }}
          state={'normal'}
          id={'fileA'}
          validation={props.fileAValid !== '' ? false : true}
          validMessage={t('파일이 업로드되지 않았습니다.')}
        />
        <UploadInput
          theme={props.theme}
          label={t('영문 주민등록 등본')}
          info={[t('· 발급일자 3개월 이내 / 컬러본'), t('· 영문등본에 수익자 미기재 시 수익자 여권 첨부')]}
          file={props.fileB}
          setFile={props.setFileB}
          handleFile={(e:React.ChangeEvent<HTMLInputElement>) => props.handleFileB(e)}
          btn={{
            text: t('업로드'),
            onClick: () => openFileFinder('fileB')
          }}
          state={'normal'}
          id={'fileB'}
          validation={props.fileBValid !== '' ? false : true}
          validMessage={t('파일이 업로드되지 않았습니다.')}
        />
      </div>
      <BigBtn
        theme={props.theme}
        type='primary'
        text={t('다음')}
        onClick={() => props.nextClick()}
      />
    </div>
  );
};

const SubsRequestC:React.FC<PropsC> = (props) => {
  const {t} = useTranslation();

  return (
    <div className='col-stretch gap36'>
      <div className='col-stretch gap24'>
        <TextInput
          theme={props.theme}
          label={t('고용주 이름')}
          inputValue={props.employerName}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.employerNameChange(e)}
          placeholder={t('고용주 이름 입력...')}
          icon={false}
          validation={props.employerNameValid !== '' ? false: true}
          validMessage={t('고용주 이름을 확인해주세요.')}
          inputType='text'
        />
        <TextInput
          theme={props.theme}
          label={t('업종')}
          inputValue={props.bsnType}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.bsnTypeChange(e)}
          placeholder={t('업종 입력...')}
          icon={false}
          validation={props.bsnTypeValid !== '' ? false: true}
          validMessage={t('업종을 확인해주세요.')}
          inputType='text'
        />
        <TextInput
          theme={props.theme}
          label={t('회사주소')}
          inputValue={props.bsnAddress}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.bsnAddressChange(e)}
          placeholder={t('회사주소 입력...')}
          icon={false}
          validation={props.bsnAddressValid !== '' ? false: true}
          validMessage={t('회사주소를 확인해주세요.')}
          inputType='text'
        />
        <TextInput
          theme={props.theme}
          label={t('회사 내 현재 직급')}
          inputValue={props.position}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.positionChange(e)}
          placeholder={t('회사 내 현재 직급 입력...')}
          icon={false}
          validation={props.positionValid !== '' ? false: true}
          validMessage={t('직급을 확인해주세요.')}
          inputType='text'
        />
        <TextInput
          theme={props.theme}
          label={t('현재 회사 근속 기간')}
          inputValue={props.periodService}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.periodServiceChange(e)}
          placeholder={t('현재 회사 근속 기간 입력...')}
          icon={false}
          validation={props.periodServiceValid !== '' ? false: true}
          validMessage={t('근속 기간을 확인해주세요.')}
          inputType='text'
        />
      </div>
      <BigBtn
        theme={props.theme}
        type='primary'
        text={t('다음')}
        onClick={() => props.nextClick()}
      />
    </div>
  );
}

const SubsRequestD:React.FC<PropsD> = (props) => {
  const {t} = useTranslation();

  return (
    <div className='col-stretch gap36'>
      <div className='col-stretch gap24'>
        <TextInput
          theme={props.theme}
          label={t('연수입(USD)')}
          inputValue={props.income}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.incomeChange(e)}
          placeholder={t('연수입 입력...')}
          icon={false}
          validation={props.incomeValid !== '' ? false: true}
          validMessage={t('연수입을 확인해주세요.')}
          inputType='tel'
        />
        <TextInput
          theme={props.theme}
          label={t('유동자산(USD)')}
          inputValue={props.liquidAsset}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.liquidAssetChange(e)}
          placeholder={t('유동자산 입력...')}
          icon={false}
          validation={props.liquidAssetValid !== '' ? false: true}
          validMessage={t('유동자산을 확인해주세요.')}
          inputType='tel'
        />
      </div>
      <BigBtn
        theme={props.theme}
        type='primary'
        text={t('다음')}
        onClick={() => props.nextClick()}
      />
    </div>
  );
}

const SubsRequestE:React.FC<PropsE> = (props) => {
  const {t} = useTranslation();

  return (
    <div className='col-stretch gap36'>
      <div className='col-stretch gap24'>
        <TextInput
          theme={props.theme}
          label={t('수익자 영문 이름')}
          inputValue={props.name}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.nameChange(e)}
          placeholder={t('수익자 영문 이름 입력...')}
          icon={false}
          validation={props.nameValid !== '' ? false: true}
          validMessage={t('이름을 확인해주세요.')}
          inputType='text'
        />
        <TextInput
          theme={props.theme}
          label={t('수익자 주민등록번호')}
          inputValue={props.regNumb}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.regNumbChange(e)}
          placeholder={t('수익자 주민등록번호 입력...')}
          icon={false}
          validation={props.regNumbValid !== '' ? false: true}
          validMessage={t('주민등록번호를 확인해주세요.')}
          inputType='tel'
        />
        <TextInput
          theme={props.theme}
          label={t('수익자의 피보험자와의 관계')}
          inputValue={props.relation}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => props.relationChange(e)}
          placeholder={t('수익자의 피보험자와의 관계 입력...')}
          icon={false}
          validation={props.relationValid !== '' ? false: true}
          validMessage={t('관계를 확인해주세요.')}
          inputType='text'
        />
      </div>
      <BigBtn
        theme={props.theme}
        type='primary'
        text={t('제출')}
        onClick={() => props.nextClick()}
      />
    </div>
  )
};

export default SubsRequestPage;