import React, { Dispatch, SetStateAction } from 'react';
import BigBtn from '../btn/big_btn';

interface Props{
  theme: string;
  text: string;
  btn: any; // name: string, onClick: () => void
}

const ModalOk:React.FC<Props> = (props) => {
  return (
    <div className={`modal-overlay zi-modal bgBlack05 ${props.theme}`}>
      <div className={`modal bg15 ${props.theme} modal-shadow`}>
        <div className={`modal-text m17 c1 ${props.theme}`}>{props.text}</div>
        <BigBtn
          theme={props.theme}
          type='primary'
          text={props.btn.name}
          onClick={props.btn.onClick}
        />
      </div>
    </div>
  );
};

export default ModalOk;