import { arrowBackDark, arrowBackLight, arrowFrontDark, arrowFrontLight } from 'configs/icon_route';
import React, { Dispatch, SetStateAction, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

interface Props{
  theme: string;
  value: Date | null;
  setValue: Dispatch<SetStateAction<Date | null>>;
  setValid: Dispatch<SetStateAction<string>>;
}

const MonthPicker:React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const today = new Date();
  const maxDate = new Date(today.getFullYear() + 20, today.getMonth(), today.getDate());

  const MONTHS = [
    t('1월'),
    t('2월'),
    t('3월'),
    t('4월'),
    t('5월'),
    t('6월'),
    t('7월'),
    t('8월'),
    t('9월'),
    t('10월'),
    t('11월'),
    t('12월'),
  ];

  const renderMonthContent = (month: any) => {
    return <span>{`${month + 1}${t('월')}`}</span>;
  };

  return (
    <ReactDatePicker
      // formatWeekDay={(nameOfDay) => returnWeekDay(nameOfDay)}
      dateFormat='yyyy/MM'
      showMonthYearPicker
      showFullMonthYearPicker
      shouldCloseOnSelect
      minDate={today}
      maxDate={maxDate}
      selected={props.value}
      onChange={(date:Date) => {
        props.setValue(date);
        props.setValid('');
      }}
      customInput={
        <div className={`month-picker-input ${props.value ? 'c1' : 'c10'} m17 radius8 borderC13 bgBG2 ${props.theme}`}>
          {
            props.value ?
            `${(props.value.getFullYear().toString())}/${(props.value.getMonth() + 1).toString()}` : t('신용카드 유효기간 입력...')
          }
        </div>
      }
      popperClassName='date-period-popper'
      calendarClassName={`date-period-calendar bgBG2 ${props.theme}`}
      renderMonthContent={renderMonthContent}
      renderCustomHeader={({
        date,
        changeYear,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className={`date-period-popper-header row-center bgBG2 ${props.theme}`}>
          <button
            type='button'
            onClick={decreaseYear}
            disabled={prevMonthButtonDisabled}
            className='date-period-popper-arrow'
          >
            <img src={props.theme === 'dark' ? arrowBackDark : arrowBackLight} alt='decrease month' />
          </button>
          <div className={`row-center gap4`}>
            <div className={`m17 c1 ${props.theme}`}>{date.getFullYear()}</div>
          </div>
          <button
            type='button'
            onClick={increaseYear}
            disabled={nextMonthButtonDisabled}
            className='date-period-popper-arrow'
          >
            <img src={props.theme === 'dark' ? arrowFrontDark : arrowFrontLight} alt='increase month' />
          </button>
        </div>
      )}
    />
  );
};

export default MonthPicker;