export const arrowBackDark = './icon/avif/arrow-back-dark.avif';
export const arrowBackLight = './icon/avif/arrow-back-light.avif';
export const arrowDownBoldDark = './icon/avif/arrow-down-bold-dark.avif';
export const arrowDownBoldLight = './icon/avif/arrow-down-bold-light.avif';
export const arrowDownSmallDark = './icon/avif/arrow-down-small-dark.avif';
export const arrowDownSmallLight = './icon/avif/arrow-down-small-light.avif';
export const arrowRightItemDark = './icon/avif/arrow-right-item-dark.avif';
export const arrowRightItemLight = './icon/avif/arrow-right-item-light.avif';
export const arrowGoDark = './icon/avif/arrow-go-dark.avif';
export const arrowGoLight = './icon/avif/arrow-go-light.avif';
export const arrowIntroduceBack = './icon/avif/arrow-introduce-back.avif';
export const arrowIntroduceNext = './icon/avif/arrow-introduce-next.avif';

export const closeInputDark = './icon/avif/close-input-dark.avif';
export const closeInputLight = './icon/avif/close-input-light.avif';
export const documentDark = './icon/avif/document-dark.avif';
export const documentLight = './icon/avif/document-light.avif';
export const eyeCloseDark = './icon/avif/eye-close-dark.avif';
export const eyeCloseLight = './icon/avif/eye-close-light.avif';
export const eyeOpenDark = './icon/avif/eye-open-dark.avif';
export const eyeOpenLight = './icon/avif/eye-open-light.avif';
export const homeActiveDark = './icon/avif/home-active-dark.avif';
export const homeActiveLight = './icon/avif/home-active-light.avif';
export const homeDark = './icon/avif/home-dark.avif';
export const homeLight = './icon/avif/home-light.avif';
export const profileActiveDark = './icon/avif/profile-active-dark.avif';
export const profileActiveLight = './icon/avif/profile-active-light.avif';
export const profileDark = './icon/avif/profile-dark.avif';
export const profileLight = './icon/avif/profile-light.avif';
export const searchDark = './icon/avif/search-dark.avif';
export const searchLight = './icon/avif/search-light.avif';
export const settingDark = './icon/avif/setting-dark.avif';
export const settingLight = './icon/avif/setting-light.avif';
export const ticketActiveDark = './icon/avif/ticket-active-dark.avif';
export const ticketActiveLight = './icon/avif/ticket-active-light.avif';
export const ticketDark = './icon/avif/ticket-dark.avif';
export const ticketLight = './icon/avif/ticket-light.avif';
export const blogLight = './icon/avif/blog-light.avif';
export const blogActiveLight = './icon/avif/blog-active-light.avif';
export const uploadDark = './icon/avif/upload-dark.avif';
export const uploadLight = './icon/avif/upload-light.avif';
export const uploadStateCancel = './icon/avif/upload-state-cancel.avif';
export const uploadStateCheck = './icon/avif/upload-state-check.avif';
export const outlinkDark = './icon/avif/outlink-dark.avif';
export const outlinkLight = './icon/avif/outlink-light.avif';
export const verticalEqualDark = './icon/avif/vertical-equal-dark.avif';
export const verticalEqualLight = './icon/avif/vertical-equal-light.avif';
export const exchangeDark = './icon/avif/exchange-dark.avif';
export const exchangeLight = './icon/avif/exchange-light.avif';
export const arrowFrontDark = './icon/avif/arrow-front-dark.avif';
export const arrowFrontLight = './icon/avif/arrow-front-light.avif';
export const closeDark = './icon/avif/close-dark.avif';
export const closeLight = './icon/avif/close-light.avif';
export const downloadDark = './icon/avif/download-dark.avif';
export const downloadLight = './icon/avif/download-light.avif';
export const addCircleDark = './icon/avif/add-circle-dark.avif';
export const addCircleLight = './icon/avif/add-circle-light.avif';
export const removeCircleDark = './icon/avif/remove-circle-dark.avif';
export const removeCircleLight = './icon/avif/remove-circle-light.avif';
export const salesManageDark = './icon/avif/sales-manage-dark.avif';
export const salesManageLight = './icon/avif/sales-manage-light.avif';
export const salesManageActiveDark = './icon/avif/sales-manage-active-dark.avif';
export const salesManageActiveLight = './icon/avif/sales-manage-active-light.avif';
export const logoutDark = './icon/avif/logout-dark.avif';
export const logoutLight = './icon/avif/logout-light.avif';
export const chat = './icon/avif/chat.avif';
export const homeAppbarLogo = './icon/avif/home-appbar-logo.avif';
export const productProcessCheck = './icon/avif/product-process-check.avif';
export const productProcessGoing = './icon/avif/product-process-going.avif';
export const productProcessReject = './icon/avif/product-process-reject.avif';

//sales
export const worksDark = './icon/avif/works-dark.avif';
export const worksLight = './icon/avif/works-light.avif';
export const worksActiveDark = './icon/avif/works-active-dark.avif';
export const worksActiveLight = './icon/avif/works-active-light.avif';
export const salesProfileDark = './icon/avif/sales-profile-dark.avif';
export const salesProfileLight = './icon/avif/sales-profile-light.avif';
export const salesProfileActiveDark = './icon/avif/sales-profile-active-dark.avif';
export const salesProfileActiveLight = './icon/avif/sales-profile-active-light.avif';
export const customerDark = './icon/avif/customer-dark.avif';
export const customerLight = './icon/avif/customer-light.avif';
export const customerActiveDark = './icon/avif/customer-active-dark.avif';
export const customerActiveLight = './icon/avif/customer-active-light.avif';
export const checkDark = './icon/avif/check-dark.avif';
export const checkLight = './icon/avif/check-light.avif';
export const addUserDark = './icon/avif/add-user-dark.avif';
export const addUserLight = './icon/avif/add-user-light.avif';
export const sortDark = './icon/avif/sort-dark.avif';
export const sortLight = './icon/avif/sort-light.avif';
export const idSmallDark = './icon/avif/id-small-dark.avif';
export const idSmallLight = './icon/avif/id-small-light.avif';
export const moreDark = './icon/avif/more-dark.avif';
export const moreLight = './icon/avif/more-light.avif';

export const logoDark = './img/avif/logo-dark.avif';
export const logoLight = './img/avif/logo-light.avif';

export const logoAxa = './img/avif/logo-axa.avif';
export const logoGenerali = './img/avif/logo-generali.avif';
export const logoSunlife = './img/avif/logo-sunlife.avif';
export const logoZurich = './img/avif/logo-zurich.avif';
export const logoChubb = './img/avif/logo-chubb.avif';

export const logoLoginDark = './img/avif/logo-login-dark.avif';
export const logoLoginLight = './img/avif/logo-login-light.avif';


//img

export const d3CoinYellow = './img/avif/coin-yellow.avif';
export const d3CoinGradient = './img/avif/coin-gradient.avif';
export const d3CoinGold = './img/avif/coin-gold.avif';
export const d3Document = './img/avif/document.avif';
export const d3Target = './img/avif/target.avif';
export const d3Award = './img/avif/award.avif';
export const d3Wallet = './img/avif/wallet.avif';
export const d3CheckGold = './img/avif/check-gold.avif';
export const d3CheckBlue = './img/avif/check-blue.avif';

// flag
export const flagKr = './icon/avif/flag/kr.avif';
export const flagUs = './icon/avif/flag/us.avif';
export const flagHk = './icon/avif/flag/hk.avif';
export const flagJp = './icon/avif/flag/jp.avif';
export const flagCn = './icon/avif/flag/cn.avif';
export const flagTh = './icon/avif/flag/th.avif';
export const flagVn = './icon/avif/flag/vn.avif';

export const flagCircleKr = './icon/avif/flag/circle-kr.avif';
export const flagCircleTh = './icon/avif/flag/circle-th.avif';
export const flagCircleVn = './icon/avif/flag/circle-vn.avif';
export const flagCircleJp = './icon/avif/flag/circle-jp.avif';
export const flagCircleCn = './icon/avif/flag/circle-cn.avif';

// introduce
export const introduce1 = './img/avif/introduce1.avif';
export const introduce2 = './img/avif/introduce2.avif';
export const introduce3 = './img/avif/introduce3.avif';

// banner
export const appBanner00 = './img/avif/app-banner-00.avif';
export const appBanner01 = './img/avif/app-banner-01.avif';
export const appBanner02 = './img/avif/app-banner-02.avif';

export const homeBannerId = './img/avif/home-banner-id.avif';
export const homeBannerChat = './img/avif/home-banner-chat.avif';
export const reviewBannerReward = './img/avif/review-banner.avif';

// review
export const reviewAvatar01 = './icon/avif/review-avatar-01.avif';
export const reviewAvatar02 = './icon/avif/review-avatar-02.avif';
export const reviewAvatar03 = './icon/avif/review-avatar-03.avif';
export const reviewAvatar04 = './icon/avif/review-avatar-04.avif';
export const reviewAvatar05 = './icon/avif/review-avatar-05.avif';
export const reviewFillStar = './icon/avif/fill-star.avif';

// product
export const sfPromotion = './img/avif/swiss-fortune-promotion.avif';


export const piNextAssign = './icon/avif/product-next-assign.avif';
export const piInfinity = './icon/avif/product-infinity.avif'
export const piInherit = './icon/avif/product-inherit.avif';
export const piIpDiv = './icon/avif/product-ip-div.avif';
export const piRecurWithdraw = './icon/avif/product-recur-withdraw.avif';
export const piShield = './icon/avif/product-shield.avif';

export const pSimulation = './img/avif/plan-simulation.avif';

export const pLabelHot = './icon/avif/product-hot-label.avif';