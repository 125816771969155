import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {  returnExchange } from 'utils/common/functions';
import { useFlow } from '../../configs/stackflow';
import { productProcessGoing, productProcessReject } from 'configs/icon_route';

interface Props{
  theme: string;
  logo: string;
  name: string;
  paymentPeriod: string;
  minPayment: number;
  exchange: boolean;
  isLast: boolean;
  chips?: Array<any>;
  productCode: string;
  state?: string;
  company: string;
  hideStatus?: boolean;
  labels?: Array<string>;
  irrListLabel: {type: string, data: string};
}

const HomeProductItem:React.FC<Props> = (props) => {
  const { unit } = useSelector((state:RootState) => state.exchange);
  const {t} = useTranslation();
  const { push } = useFlow();

  const movePage = () => {
    push("ProductInfoPage", {
      userType: 'user',
      isSubscribed: false,
      productCode: props.productCode,
    });
  };

  return (
    <div className={`home-product-item col-stretch gap9 ${props.isLast ? '' : 'borderBottomC14W05'} ${props.theme}`} onClick={movePage}>
      {/* {
        props.labels && (
          <div className='product-label-badge-wrapper'>
            <img alt='label' src={props.labels[0]} />
          </div>
        )
      } */}
      {
        props.hideStatus === true ? (
          props.labels ? <div /> : null
        ) : (
          <div className='home-product-item-chips gap8 row-center'>
            {
              props.state === 'reject' ?
              <div className='home-product-item-process row-center gap6'>
                <img src={productProcessReject} alt='process' />
                <div className='colorOrange m13'>다시제출</div>
              </div> :
              props.state ?
              <div className='home-product-item-process row-center gap6'>
                <img src={productProcessGoing} alt='process' />
                <div className='tSLG m13'>{props.state}</div>
              </div> : null
            }
          </div>
        )
      }
      {/* <div className='row-center gap12'>
        <img className='home-product-item-logo' src={props.logo} alt='product logo' />
        <div className={`m15 c5 ${props.theme}`}>{props.name}</div>
      </div> */}
      <div className='col-stretch gap9'>
        <div className='row-center gap6'>
          <img className='home-my-insure-item-logo' src={props.logo} alt='보험사 로고' />
          <div className='m13 c1'>{props.company}</div>
        </div>
        <div className='col-stretch gap8'>
          <div className='col-stretch gap5'>
            <div className={`m15 c1 ${props.theme}`}>{props.name}</div>
            <div className='col-start gap12'>
              <div className={`m13 c1 ${props.theme}`}><span className={`b17 ${props.theme}`}>{t('최소')} {returnExchange(props.minPayment, props.exchange ? 'USD' : unit)?.toLocaleString()} <span className={`r15 c7 ${props.theme}`}>{props.exchange ? 'USD' : unit} / year</span></span></div>
            </div>
          </div>
          <div className='row-center gap8'>
            {
              props.irrListLabel.type === 'string' ? (
                <div className='product-irr-label-wrapper radius4 m13'>{props.irrListLabel.data}</div>
              ) : (
                <div className='product-irr-label-wrapper radius4 m13'>예상 연평균 이율 <b>{props.irrListLabel.data}</b></div>
              )
            }
            <div className={`home-product-item-period c1 m13 bg16 radius4 ${props.theme}`}>{props.paymentPeriod}</div>
            {
              props.chips?.map((chip: any, i: number) => (
                <div key={String(i)} className={`home-product-item-period c1 m13 bg15 radius4 ${props.theme}`}>
                  {chip}
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProductItem;