import React, { Dispatch, SetStateAction } from 'react';
import { blogActiveLight, blogLight, homeActiveDark, homeActiveLight, homeDark, homeLight, profileActiveDark, profileActiveLight, profileDark, profileLight, ticketActiveDark, ticketActiveLight, ticketDark, ticketLight } from '../../configs/icon_route';
import { useTranslation } from 'react-i18next';

interface Props{
  theme: string;
  activeIdx: number;
  setActiveIdx: Dispatch<SetStateAction<number>>;
}

const BottomNav:React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const clickBtn = (idx: number) => {
    if (idx === 0) {
      props.setActiveIdx(0);
    } else if (idx === 1) {
      props.setActiveIdx(1);
    } else if (idx === 2) {
      props.setActiveIdx(2);
    } else if (idx === 3) {
      props.setActiveIdx(3);
    } else return;
  };

  return (
    <div className={`btm-nav bgBG2 borderTopC14W05 ${props.theme}`}>
      <div className='btm-nav-btn' onClick={() => clickBtn(0)}>
        <div className='btm-nav-btn-icon-wrap'>
          <img
            className={`btm-nav-btn-icon-img ${props.activeIdx === 0 ? 'active' : ''}`}
            src={props.theme === 'dark' ? homeDark : homeLight}
            alt='홈'
          />
          <img
            className={`btm-nav-btn-icon-img-active ${props.activeIdx === 0 ? 'active' : ''}`}
            src={props.theme === 'dark' ? homeActiveDark : homeActiveLight}
            alt='홈'
          />
        </div>
        <div className={`btm-nav-btn-name c1 ${props.theme}`}>{t('홈')}</div>
      </div>
      <div className='btm-nav-btn' onClick={() => clickBtn(1)}>
        <div className='btm-nav-btn-icon-wrap'>
          <img
            className={`btm-nav-btn-icon-img ${props.activeIdx === 1 ? 'active' : ''}`}
            src={blogLight}
            alt='블로그'
          />
          <img
            className={`btm-nav-btn-icon-img-active ${props.activeIdx === 1 ? 'active' : ''}`}
            src={blogActiveLight}
            alt='블로그'
          />
        </div>
        <div className={`btm-nav-btn-name c1 ${props.theme}`}>{t('블로그/기사')}</div>
      </div>
      <div className='btm-nav-btn' onClick={() => clickBtn(2)}>
        <div className='btm-nav-btn-icon-wrap'>
          <img
            className={`btm-nav-btn-icon-img ${props.activeIdx === 2 ? 'active' : ''}`}
            src={props.theme === 'dark' ? ticketDark : ticketLight}
            alt='상품'
          />
          <img
            className={`btm-nav-btn-icon-img-active ${props.activeIdx === 2 ? 'active' : ''}`}
            src={props.theme === 'dark' ? ticketActiveDark : ticketActiveLight}
            alt='상품'
          />
        </div>
        <div className={`btm-nav-btn-name c1 ${props.theme}`}>{t('상품')}</div>
      </div>
      <div className='btm-nav-btn' onClick={() => clickBtn(3)}>
        <div className='btm-nav-btn-icon-wrap'>
          <img
            className={`btm-nav-btn-icon-img ${props.activeIdx === 3 ? 'active' : ''}`}
            src={props.theme === 'dark' ? profileDark : profileLight}
            alt='마이페이지'
          />
          <img
            className={`btm-nav-btn-icon-img-active ${props.activeIdx === 3 ? 'active' : ''}`}
            src={props.theme === 'dark' ? profileActiveDark : profileActiveLight}
            alt='마이페이지'
          />
        </div>
        <div className={`btm-nav-btn-name c1 ${props.theme}`}>{t('마이페이지')}</div>
      </div>
    </div>
  );
};

export default BottomNav;