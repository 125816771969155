import React from 'react';
import { exchangeDark, exchangeLight, flagHk, flagKr, flagUs } from '../../configs/icon_route';
import { useFlow } from '../../configs/stackflow';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface Props{
  theme: string;
  userType?: string;
}

const ExchangeBanner:React.FC<Props> = (props) => {
  const {exp, syncDate, HKD, USD} = useSelector((state: RootState) => state.exchange);

  const bannerClick = () => {
    // if (props.userType === 'admin' || props.userType === 'seller') {
    //   return;
    // } else {
    //   push("ExchangeCalculatePage", {permission: props.userType ?? '', selectedIdx: 0});
    // }
  };

  return (
    <div
      className={`exchange-banner col-stretch gap12 bgBG2 bg2Shadow ${props.theme}`}
      onClick={() => bannerClick()}
    >
      {
        props.userType === 'admin' || props.userType === 'seller' ?
        <div className={`exchange-banner-two col-stretch borderC14 radius8 ${props.theme}`}>
          <div className={`exchange-banner-top two row-center borderBottomC14 ${props.theme}`}
            // onClick={() => push("ExchangeCalculatePage", {permission: props.userType ?? '', selectedIdx: 0})}
          >
            <div className='exchange-banner-unit-wrap row-center gap6'>
              <img
                className={`borderC14 ${props.theme}`}
                src={flagUs}
                alt='country'
              />
              <div className={`m15 c1 ${props.theme}`}>{1} {'USD'}</div>
            </div>
            <img
              className='exchange-banner-icon'
              src={props.theme === 'dark' ? exchangeDark : exchangeLight}
              alt='equal'
            />
            <div className='exchange-banner-unit-wrap row-center gap6'>
              <img
                className={`borderC14 ${props.theme}`}
                src={
                  flagKr
                }
                alt='country'
              />
              <div className={`m15 c1 ${props.theme}`}>{USD} KRW</div>
            </div>
          </div>
          <div className={`exchange-banner-top two row-center ${props.theme}`}
          // onClick={() => push("ExchangeCalculatePage", {permission: props.userType ?? '', selectedIdx: 1})}
          >
            <div className='exchange-banner-unit-wrap row-center gap6'>
              <img
                className={`borderC14 ${props.theme}`}
                src={flagUs}
                alt='country'
              />
              <div className={`m15 c1 ${props.theme}`}>{1} {'USD'}</div>
            </div>
            <img
              className='exchange-banner-icon'
              src={props.theme === 'dark' ? exchangeDark : exchangeLight}
              alt='equal'
            />
            <div className='exchange-banner-unit-wrap row-center gap6'>
              <img
                className={`borderC14 ${props.theme}`}
                src={
                  flagKr
                }
                alt='country'
              />
              <div className={`m15 c1 ${props.theme}`}>{HKD} KRW</div>
            </div>
          </div>
        </div> :
        <div className={`exchange-banner-top row-center ${props.theme}`}>
          <div className='exchange-banner-unit-wrap row-center gap6'>
            <img
              src={flagUs}
              alt='country'
            />
            <div className={`m15 c1 ${props.theme}`}>{1} {'USD'}</div>
          </div>
          <img
            className='exchange-banner-icon'
            src={props.theme === 'dark' ? exchangeDark : exchangeLight}
            alt='equal'
          />
          <div className={`exchange-banner-unit-wrap row-center gap6`}>
            <img
              className={`${props.theme} borderC14`}
              src={
                flagKr
              }
              alt='country'
            />
            <div className={`m15 c1 ${props.theme}`}>{USD} KRW</div>
          </div>
        </div>
      }
      {/* <div className={`exchange-banner-date m13 c9 ${props.theme}`}>{syncDate}</div> */}
      {/* <div className={`exchange-banner-date m13 c9 ${props.theme}`}>{syncDate} ({exp})</div> */}
      {/* <div className={`exchange-banner-date m13 c9 ${props.theme}`}>{exp}</div> */}
    </div>
  );
};

export default ExchangeBanner;