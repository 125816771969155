import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {throttle} from 'throttle-debounce';

import ChannelService from 'configs/channel_service';
import {Stack} from 'configs/stackflow';
import initFirebase from 'utils/firebase/init_firebase';
import {getApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {RootState} from './store/store';
import {authSlice, setIsMobile} from './store/reducers/authReducer';

import './stylesheets/globals.scss';
import updateFCMToken from 'utils/firebase_firestore/update_fcm_token';

declare var window: any;
declare var mtw_req_fcm_token: any;

const App = () => {
  const { type } = useSelector((state: RootState) => state.theme);

  const dispatch = useDispatch();

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.setProperty('background-color', type === 'dark' ? '#000' : '#ebebeb');
    }
  }, [type]);

  useEffect(() => {
    if (!window.location.href.includes('wv=y')) {
      window.close();
    }

    const isMobile = window.innerWidth < 1024;
    dispatch(setIsMobile({isMobile}));

    window.addEventListener('resize', throttle(300, () => {
      const isMobile = window.innerWidth < 1024;
      dispatch(setIsMobile({isMobile}));
    }));

    initFirebase();

    const app = getApp();
    const auth = getAuth(app);

    auth.onAuthStateChanged(async (user) => {
      if (user === null) {
        dispatch(authSlice.actions.logout());
      } else {
        dispatch(authSlice.actions.login());
        try {
          mtw_req_fcm_token.postMessage('');
        } catch (err) {}

        ChannelService.loadScript();
        ChannelService.boot({
          "pluginKey": "46c73234-be7c-471a-9b61-3308c179c462", // fill your plugin key
          "hideChannelButtonOnBoot": true,
          "memberId": user.phoneNumber ?? '',
        });
      }
    });
  }, []);

  return (
    <Stack />
    // <StrictMode>
    //   <Suspense>
        
    //   </Suspense>
    // </StrictMode>
  );
};

export default App;
